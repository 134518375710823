import React, { useEffect, useRef, useState } from "react";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import { getTokenFromLocalStorage } from "../../../redux/functions/function";
import {
  getForPaymentAPI,
  getInTransitDetailsAPI,
  getIntransitListAPI,
  getInvoiceListAPI,
  getInvoiceListDetailsAPI,
} from "../../../redux/functions/API";
import Skeleton from "react-loading-skeleton";
import TableComponent from "../../templates/TableComponent/TableComponent";
import ModalComponent from "../../templates/ModalComponent/ModalComponent";
import {
  ArrowPathIcon,
  CircleStackIcon,
  ClipboardDocumentIcon,
  ClockIcon,
  DocumentIcon,
  MapPinIcon,
  PaperAirplaneIcon,
  TagIcon,
  TruckIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import CircularLoading from "../../../lottie/circular_loading_theme_1.json";
import { TokenModel } from "../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import {
  getOrderManagement,
  setInvoiceList,
  setInvoiceDetails,
  getIntransitList,
  setIntransitLists,
  getForIntransitStatusDetails,
  setForIntransitStatusDetails,
} from "../../../redux/functions/storageSlice";
import InvoiceListType from "../../../model/types/InvoiceListType";
import ButtonComponent from "../../templates/ButtonComponent/ButtonComponent";
import { PDFDocument } from "pdf-lib";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import InvoicePDF from "./invoicePDF/InvoicePDF";
import InvoiceDetailType from "../../../model/types/InvoiceDetailType";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import IntransitListType from "../../../model/types/IntransitListType";
import logo from "./dealogikal-app-dark_p4svpl.png";
import { intransittype } from "../../../model/types/InTransitStatus";
import { StorageStatesModel } from "../../../model/redux/interface/StorageStatesModel";
import ShippingTimeline from "../../templates/ShippingTimelineComponent/ShippingTimelineComponent";

const MyOrderManagementComp = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const dispatch = useAppDispatch();
  const orderManagement = useAppSelector(getOrderManagement);
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const intransit = useAppSelector(getIntransitList);
  //const key: keyof StorageStatesModel["inTransitStatus"] = "intransit";
  const [statusKey, setStatusKey] = useState<string>("intransit");
  const intransitStatusDetails = useAppSelector((state) =>
    getForIntransitStatusDetails(state, statusKey)
  );

  // useState
  const [isOrderInvoiceFetching, setIsOrderInvoiceFetching] =
    useState<boolean>(true);
    const [isPaymentFetching, setIsPaymentFetching] = useState<boolean>(true);  
  const [orderInvoiceList, setOrderInvoiceList] = useState<InvoiceListType[]>(
    orderManagement.invoiceList
  );
  const [paymentList, setPaymentList] = useState<any>();
  const [isIntransitFetching, setIsIntransitFetching] = useState<boolean>(true);
  const [intransitList, setintransitList] =
    useState<IntransitListType[]>(intransit);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCompletedModalOpen, setIsCompletedModalOpen] =
    useState<boolean>(false);
  const [selectedInvoiceRow, setSelectedInvoiceRow] = useState<
    InvoiceDetailType[] | null
  >(null);

  // for Tab Navigation
  const [comletedActiveNav, setComletedActiveNav] =
    useState<string>("Order Details");
  const [activeNav, setActiveNav] = useState<string>("Payment");
  const navItems = [
    { text: "Payment" },
    { text: "Order Invoice" },
    { text: "In Transit" },
    { text: "Completed" },
  ];

  const tabs = [
    "Order Details",
    "Vehicle Details",
    "Logistic Details",
    "Supplier Details",
  ];

  const orderItems = [
    { name: "Automotive Diesel Oil", quantity: 6000 },
    { name: "Unleaded Gasoline", quantity: 6000 },
    { name: "Premium Gasoline", quantity: 6000 },
  ];

  const statuses = [
    {
      date: "Oct 21",
      status: "Supplier and Logistic Payment Verification",
      isCompleted: true,
    },
    { date: "Oct 24", status: "To Load", isCompleted: true },
    { date: "Oct 25", status: "In Transit", isCompleted: true },
    { date: "Oct 23", status: "Ongoing", isCompleted: false },
    { date: "Oct 23", status: "Delivered", isCompleted: false },
  ];

  const setActiveNavTab = (activeItem: string) => {
    setActiveNav(activeItem);
  };

  // for Table Component
  const tableHeader = [
    { name: "reference_number", value: "DL Number", icon: TagIcon },
    { name: "invoice_number", value: "Invoice", icon: ClipboardDocumentIcon },
    { name: "supplier", value: "Supplier", icon: UserIcon },
    { name: "total_vat_inc", value: "Amount", icon: CircleStackIcon },
  ];

  const tableIntransitHeader = [
    {
      name: "reference_number",
      value: "Delivery #",
      icon: ClipboardDocumentIcon,
    },
    {
      name: "logistic_name",
      value: "Logistic Name",
      icon: TruckIcon,
    },
    {
      name: "driver_name",
      value: "Driver Name",
      icon: UserIcon,
    },
    {
      name: "status",
      value: "Status",
      icon: ArrowPathIcon,
    },
  ];

  const tablePaymentHeader = [
    {
      name: "reference_number",
      value: "Reference Number",
      icon: ClipboardDocumentIcon,
    },
    {
      name: "commodities",
      value: "Commodities",
      icon: ClipboardDocumentIcon,
    },
    {
      name: "volume",
      value: "Volume Qty",
      icon: ClipboardDocumentIcon,
    },
    {
      name: "reverse_auction_id",
      value: "Reverse Auction No:",
      icon: ClipboardDocumentIcon,
    },
    {
      name: "delivery_location",
      value: "Delivery Location",
      icon: ClipboardDocumentIcon,
    },
    {
      name: "shipping_method",
      value: "Shipping Method",
      icon: ClipboardDocumentIcon,
    },
    {
      name: "status",
      value: "Status",
      icon: ClipboardDocumentIcon,
    },
    {
      name: "delivery_date",
      value: "Delivery Date",
      icon: ClipboardDocumentIcon,
    },
  ];

  const dummyCompletedList = [
    {
      delivery_number: "DL-896663",
      logistic_name: "Maxim",
      driver_name: "Rey",
      status: "completed",
    },
    {
      delivery_number: "DL-896663",
      logistic_name: "Angkas",
      driver_name: "Rey",
      status: "completed",
    },
    {
      delivery_number: "DL-896663",
      logistic_name: "Maxin",
      driver_name: "Rey",
      status: "completed",
    },
  ];

  // for handleInTransitClick
  const [isModalInTransitOpen, setIsModalInTransitOpen] = useState(false);
  const [forRecievingInTransitDetails, setForRecievingInTransitDetails] =
    useState<intransittype[]>(intransitStatusDetails);
  const [prevBookingId, setPrevBookingId] = useState<string | null>(null);
  const [transitStatus, setTransitStatus] = useState("intransit");
  const [selectedTransit, setSelectedTransit] = useState<
    intransittype[] | undefined
  >();

  // fetching intransit list
  useEffect(() => {
    const fetchData = async () => {
      if (isIntransitFetching && intransit.length === 0) {
        try {
          const response: any = await getIntransitListAPI(_token, userData._id);
          if (response.statusCode === 200) {
            const newIntransitList = response.data.items.map((item: any) => {
              // Combine first name and last name
              const driverName = `${item.driver_firstname} ${item.driver_lastname}`;
              let status = "";
              switch (item.order_status_id) {
                case 8:
                  status = "In-transit";
                  break;
                case 9:
                  status = "To Load";
                  break;
                case 10:
                  status = "Confirmed";
                  break;
                default:
                  status = "Unknown";
                  break;
              }

              return {
                ...item,
                driver_name: driverName,
                status,
              };
            });
            dispatch(setIntransitLists(newIntransitList));
          }
        } finally {
          setIsIntransitFetching(false);
        }
      } else if (intransit.length !== 0) {
        let updatedIntransit: IntransitListType[] = [];

        intransit.forEach((list: any) => {
          // Combine first name and last name
          const driverName = `${list.driver_firstname} ${list.driver_lastname}`;

          let status = "";
          switch (list.order_status_id) {
            case 8:
              status = "In-transit";
              break;
            case 9:
              status = "To Load";
              break;
            case 10:
              status = "Confirmed";
              break;
          }

          const newIntransit: IntransitListType = {
            ...list,
            driver_firstname: list.driver_firstname,
            driver_lastname: list.driver_lastname,
            driver_name: driverName, // Add the combined name to the item
            logistic_name: list.logistic_name,
            offer_bookings_id: list.offer_bookings_id,
            order_status_id: list.order_status_id,
            reference_number: list.reference_number,
            status,
          };
          updatedIntransit = [...updatedIntransit, newIntransit];
        });
        setintransitList(updatedIntransit);
      }
    };

    fetchData();
  }, [
    isIntransitFetching,
    _token,
    userData._id,
    intransit.length,
    dispatch,
    intransit,
  ]);

  // fetching invoice list
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          isOrderInvoiceFetching &&
          orderManagement.invoiceList.length === 0
        ) {
          const response: any = await getInvoiceListAPI(_token, userData._id);
          if (response.statusCode === 200) {
            const newInvoiceList = [
              ...orderManagement.invoiceList,
              ...response.data.items,
            ];
            dispatch(setInvoiceList(newInvoiceList));
          }
          setIsOrderInvoiceFetching(false);
        } else if (orderManagement.invoiceList.length > 0) {
          const updatedOrderInvoiceList: InvoiceListType[] =
            orderManagement.invoiceList.map((invoice: any) => {
              const newOrderInvoice: InvoiceListType = {
                invoice_id: invoice.invoice_id,
                invoice_number: invoice.invoice_number,
                reference_number: invoice.reference_number,
                supplier: invoice.supplier,
                total_vat_inc: invoice.total_vat_inc,
              };
              return newOrderInvoice;
            });
          setOrderInvoiceList(updatedOrderInvoiceList);
          setIsOrderInvoiceFetching(false);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
        setIsOrderInvoiceFetching(false);
      }
    };

    fetchData();
  }, [
    isOrderInvoiceFetching,
    _token,
    userData._id,
    dispatch,
    orderManagement.invoiceList,
  ]);

  // fetching Payment
  useEffect(() => {
    const fetchData = async () => {
      if (isPaymentFetching) {
        const response: any = await getForPaymentAPI(_token, userData._id);
        if (response.statusCode === 200) {
          const forPayment = [...response.data.items];
          setPaymentList(forPayment);
        } else {
          console.error("error on fetching Payment API");
        }
        setIsPaymentFetching(false)
      }
    };
    fetchData();
  }, [_token, isPaymentFetching, userData._id]);
  
  const [selectedInvoiceListDetails, setSelectedInvoiceListDetails] = useState<
    InvoiceDetailType[]
  >([]);

  const handleRowClick = async (invoice_number: string) => {
    try {
      // Find the invoice in the list
      const selectedInvoice = selectedInvoiceListDetails.find(
        (invoice) => invoice.items.invoice_number === invoice_number
      );

      if (selectedInvoice) {
        // If the invoice is already selected, set it and open the modal
        setSelectedInvoiceRow([selectedInvoice]);
        setIsModalOpen(true);
      } else {
        // If the invoice is not in the list, fetch its details
        const invoice = orderInvoiceList.find(
          (invoice) => invoice.invoice_number === invoice_number
        );

        if (invoice) {
          const response: any = await getInvoiceListDetailsAPI(
            _token,
            invoice.invoice_id
          );

          if (response.statusCode === 200) {
            const details: InvoiceDetailType = response.data;
            // Update local state and dispatch action to store the invoice details
            setSelectedInvoiceRow([details]);
            dispatch(setInvoiceDetails([details]));
            //setSelectedInvoiceListDetails(prevState => [...prevState, details]);
            setSelectedInvoiceListDetails(() => [details]);
            setIsModalOpen(true);
          } else {
            console.error(
              "Failed to fetch invoice details:",
              response.statusCode
            );
          }
        } else {
          console.error("Invoice not found in the orderInvoiceList.");
        }
      }
    } catch (error) {
      console.error("Error in handleRowClick:", error);
    }
  };

  const handlePDFDownload = async (type: "invoice" | "receipt") => {
    const content: any = contentRef.current;
    const fileName = type === "invoice" ? "Invoice.pdf" : "Receipt.pdf";

    try {
      setIsLoadingAPI(true);
      setIsDownloadingPDF(true);
      setIsButtonDisabled(true);

      // Capture the HTML content as an image
      const canvas = await html2canvas(content, { scale: 3 });

      const imgData = canvas.toDataURL("image/png");

      // Create a PDF document
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage([canvas.width, canvas.height]);

      // Embed the image in the PDF document
      const img = await pdfDoc.embedPng(imgData);
      page.drawImage(img, {
        x: 0,
        y: 0,
        width: canvas.width,
        height: canvas.height,
      });

      // Save the PDF and download it
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      saveAs(blob, fileName);

      // Reset states after download is complete
      setIsLoadingAPI(false);
      setIsDownloadingPDF(false);
      setIsButtonDisabled(false);
    } catch {
      console.log("error");

      // Reset states if an error occurs
      setIsLoadingAPI(false);
      setIsDownloadingPDF(false);
      setIsButtonDisabled(false);
    }
  };

  const handleInTransitClick = async (offer_booking_id: string) => {
    try {
      let selected = forRecievingInTransitDetails.find(
        (logistic) => logistic.offer_booking_id === parseInt(offer_booking_id)
      );

      if (!selected) {
        const bookingIdStr = offer_booking_id;
        const isBookingIdChange = bookingIdStr !== prevBookingId;

        if (isBookingIdChange) {
          const response: any = await getInTransitDetailsAPI(
            _token,
            parseInt(offer_booking_id)
          );

          if (response.statusCode === 200) {
            const newIntransitDetails = response.data.items.map(
              (item: any) => ({
                ...item,
              })
            );

            dispatch(
              setForIntransitStatusDetails({
                key: "intransit",
                value: newIntransitDetails,
              })
            );
            setForRecievingInTransitDetails(newIntransitDetails);

            // Update selected correctly after updating the state
            selected = newIntransitDetails.find(
              (logistic: intransittype) =>
                logistic.offer_booking_id === parseInt(offer_booking_id)
            );
          }
          setPrevBookingId(bookingIdStr);
        } else {
          if (intransitStatusDetails.length === 0) {
            //console.log("intransitStatusDetails", intransitStatusDetails);
          }
        }
      }

      if (selected) {
        let status = selected?.order_status_id;

        // Determine statusKey based on order_status_id
        let newStatusKey: keyof StorageStatesModel["inTransitStatus"];
        if (status === 8) {
          newStatusKey = "intransit";
        } else if (status === 9) {
          newStatusKey = "loading";
        } else if (status === 10) {
          newStatusKey = "confirmed";
        } else {
          // Handle unknown status if necessary
          return;
        }

        // `newStatusKey` is already of type `string`, so we can safely set it.
        setStatusKey(newStatusKey);
        setTransitStatus(newStatusKey);
        setSelectedTransit(selected ? [selected] : undefined);
        setIsModalInTransitOpen(
          statusKey === "intransit" || statusKey === "confirmed" ? false : true
        );
      }
    } catch (error) {
      console.error("Error in handleInTransitClick:", error);
    }
  };

  return (
    <MainConsoleLayoutComponent
      content={
        <>
          <BreadcrumbContainerComponent subtitle="Order Management" />
          <NavTabComponent
            key={Math.random()}
            nav_type="secondary"
            active_tab={activeNav}
            nav_items={navItems}
            setActiveNavHandler={setActiveNavTab}
            left_content={null} // Consider implementing search if needed
          />
          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
              {activeNav === "Payment" && (
                <>
                  {isPaymentFetching ? (
                    <div className="-my-2">
                      <Skeleton height={40} count={1} />
                      <Skeleton height={40} count={5} />
                    </div>
                  ) : (
                    <TableComponent
                      tableHeader={tablePaymentHeader}
                      tableData={paymentList}
                      currentPage={1}
                      rowsPerPage={10}
                      hasRowClick={true}
                      idStringProperty="offer_bookings_id"
                      handleRowClick={handleInTransitClick}
                      emptyState={{
                        primaryMessage: "No Intransit.",
                        secondaryMessage:
                          "Get started by creating a new template.",
                        buttonText: "Create Quotation",
                        buttonOnClick() {
                          navigate("/b/select_commodity");
                        },
                      }}
                    />
                  )}
                </>
              )}
              {activeNav === "Order Invoice" && (
                <>
                  {isOrderInvoiceFetching ? (
                    <div className="-my-2">
                      <Skeleton height={40} count={1} />
                      <Skeleton height={40} count={5} />
                    </div>
                  ) : (
                    <TableComponent
                      tableHeader={tableHeader}
                      tableData={orderInvoiceList}
                      currentPage={1}
                      rowsPerPage={10}
                      idStringProperty="invoice_number"
                      handleRowClick={handleRowClick}
                      emptyState={{
                        primaryMessage: "No Order Invoice",
                        secondaryMessage:
                          "Get started by creating a new template.",
                        buttonText: "Create Quotation",
                        buttonOnClick() {
                          navigate("/b/select_commodity");
                        },
                      }}
                    />
                  )}
                </>
              )}
              {activeNav === "In Transit" && (
                <>
                  {isOrderInvoiceFetching ? (
                    <div className="-my-2">
                      <Skeleton height={40} count={1} />
                      <Skeleton height={40} count={5} />
                    </div>
                  ) : (
                    <TableComponent
                      tableHeader={tableIntransitHeader}
                      tableData={intransitList}
                      currentPage={1}
                      rowsPerPage={10}
                      hasRowClick={true}
                      idStringProperty="offer_bookings_id"
                      handleRowClick={handleInTransitClick}
                      emptyState={{
                        primaryMessage: "No Intransit.",
                        secondaryMessage:
                          "Get started by creating a new template.",
                        buttonText: "Create Quotation",
                        buttonOnClick() {
                          navigate("/b/select_commodity");
                        },
                      }}
                    />
                  )}
                </>
              )}
              {activeNav === "Completed" && (
                <>
                  {isOrderInvoiceFetching ? (
                    <div className="-my-2">
                      <Skeleton height={40} count={1} />
                      <Skeleton height={40} count={5} />
                    </div>
                  ) : (
                    <TableComponent
                      tableHeader={tableIntransitHeader}
                      tableData={dummyCompletedList}
                      currentPage={1}
                      rowsPerPage={10}
                      idStringProperty="invoice_number"
                      handleRowClick={() => setIsCompletedModalOpen(true)}
                      emptyState={{
                        primaryMessage: "No Intransit.",
                        secondaryMessage:
                          "Get started by creating a new template.",
                        buttonText: "Create Quotation",
                        buttonOnClick() {
                          navigate("/b/select_commodity");
                        },
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <ModalComponent
            isOpen={isModalOpen}
            onCloseHandler={() => setIsModalOpen(false)}
            isXL={true}
            header={
              <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
                <DocumentIcon
                  className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
                  aria-hidden="true"
                />
                <span className="text-white inline md:text-base text-xs">
                  Invoice
                </span>
              </div>
            }
            body={
              selectedInvoiceRow && (
                <div className="flex flex-col px-10 gap-5 py-5 ">
                  <div className="flex justify-end mr-5">
                    <ButtonComponent
                      text={`${isButtonDisabled ? "Download" : "Downloading"}`}
                      icon={
                        isLoadingAPI && (
                          <Lottie
                            className="md:w-5 w-5 h-auto"
                            animationData={CircularLoading}
                            loop={true}
                          />
                        )
                      }
                      disabled={isButtonDisabled}
                      utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
												hover:bg-dealogikal-200 duration-500 rounded-full font-normal md:w-72 w-full
												${isDownloadingPDF ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
												`}
                      onClick={() => handlePDFDownload("invoice")}
                    />
                  </div>
                  <div ref={contentRef}>
                    <InvoicePDF
                      selectedInvoiceListDetails={selectedInvoiceListDetails}
                    />
                  </div>
                </div>
              )
            }
          />
          <ModalComponent
            isXL={true}
            isOpen={isCompletedModalOpen}
            onCloseHandler={() => setIsCompletedModalOpen(false)}
            header={
              <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
                <DocumentIcon
                  className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
                  aria-hidden="true"
                />
                <span className="text-white inline md:text-base text-xs">
                  Completed
                </span>
              </div>
            }
            body={
              <>
                <div className="py-10 px-10 flex justify-between gap-10">
                  <div className="bg-gray-100 w-full px-5 rounded-lg py-5 shadow-md">
                    <h2 className="text-start text-gray-400">
                      Order No: <span className="text-gray-600"> #65242</span>
                    </h2>
                    <div className="flex flex-col items-center">
                      <img
                        className="h-32 w-auto mt-5"
                        src="https://res.cloudinary.com/doehyebmw/image/upload/v1724814865/dealogikal_2.0/resources/images/big_img/7241402-removebg-preview_1_yrw3qk.png"
                        alt="icon"
                      />
                    </div>

                    <div className="mt-10">
                      <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
                        {statuses.map((item: any, index: any) => (
                          <ShippingTimeline
                            key={index}
                            date={item.date}
                            status={item.status}
                            isCompleted={item.isCompleted}
                            isLast={index === statuses.length - 1}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 w-full px-5 rounded-lg py-5 shadow-md">
                    <div className="max-w-3xl mx-auto p-4 bg-white rounded-lg shadow-md">
                      <div className="mb-4">
                        <span className="font-semibold">Order Number:</span>{" "}
                        #65242
                      </div>

                      <div className="mb-6 bg-blue-50 p-4 rounded-lg relative">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15701.122580756743!2d123.96132874643601!3d10.31941044757221!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a999d680997be1%3A0x888ed11028d84b4d!2sPusok%2C%20Lapu-Lapu%20City%2C%20Cebu!5e0!3m2!1sen!2sph!4v1724817307609!5m2!1sen!2sph"
                          width="600"
                          height="200"
                          style={{ border: 0 }}
                          loading="lazy"
                          title="map"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                        <div className="bg-white/40 rounded-md shadow-md py-2 px-4 items-center content-center">
                          <div className="flex justify-between mt-4 text-sm ">
                            <div className="flex items-center">
                              <PaperAirplaneIcon className="w-4 h-4 mr-1 text-blue-500" />
                              <span>Current Location</span>
                            </div>
                            <div className="flex items-center">
                              <MapPinIcon className="w-4 h-4 mr-1 text-blue-500" />
                              <span>Speed</span>
                            </div>
                            <div className="flex items-center">
                              <ClockIcon className="w-4 h-4 mr-1 text-blue-500" />
                              <span>Estimated Time Arrived</span>
                            </div>
                          </div>
                          <div className="flex justify-between mt-1 font-semibold">
                            <span>Gun - Go</span>
                            <span>79 kph</span>
                            <span>30 min.</span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="flex border-b">
                          {tabs.map((tab) => (
                            <button
                              key={tab}
                              className={`px-2 py-2 text-sm ${
                                comletedActiveNav === tab
                                  ? "border-b-2 border-blue-500 text-blue-500"
                                  : "text-gray-500"
                              }`}
                              onClick={() => setComletedActiveNav(tab)}
                            >
                              {tab}
                            </button>
                          ))}
                        </div>
                      </div>

                      {comletedActiveNav === "Order Details" && (
                        <table className="w-full">
                          <thead>
                            <tr className="bg-gray-100">
                              <th className="p-2 text-left">Items</th>
                              <th className="p-2 text-right">Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderItems.map((item, index) => (
                              <tr key={index} className="border-b">
                                <td className="p-2 flex items-center">
                                  <span className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-2">
                                    <MapPinIcon className="w-4 h-4 text-blue-500" />
                                  </span>
                                  {item.name}
                                </td>
                                <td className="p-2 text-right">
                                  {item.quantity.toLocaleString()}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                      {comletedActiveNav !== "Order Details" && (
                        <div className="text-center py-8 text-gray-500">
                          Content for {comletedActiveNav} would go here.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
          />

          <ModalComponent
            // isXL={
            //   statusKey === "intransit" || statusKey === "loading" || statusKey ==="receiving"
            //     ? false
            //     : true
            // }
            isXL={true}
            isOpen={isModalInTransitOpen}
            onCloseHandler={() => setIsModalInTransitOpen(false)}
            header={
              <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
                <DocumentIcon
                  className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
                  aria-hidden="true"
                />
                <span className="text-white inline md:text-base text-xs">
                  In-Transit
                </span>
              </div>
            }
            body={
              <>
                {/* {transitStatus === "intransit" && (
                  <>
                    {selectedTransit?.map((item: any) => (
                      <div className="mx-5 items-center py-16">
                        <div className="flex flex-col justify-between gap-8 md:flex-row -translate-y-9">
                          <div className="flex flex-col gap-5 w-full">
                            <div className="flex flex-col gap-1">
                              <span className="text-sm text-gray-400">
                                Buyer Name
                              </span>
                              <div className="bg-gray-200 py-1 px-4 rounded-md w-full">
                                <p className="text-base text-gray-700">
                                  {item.buyer_name}
                                </p>
                              </div>
                              <span className="text-sm text-gray-400">
                                Buyer Name
                              </span>
                              <div className="bg-gray-200 py-1 px-4 rounded-md w-full">
                                <p className="text-base text-gray-700">
                                  {item.buyer_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )} */}
                {transitStatus === "loading" && (
                  <>
                    <div className="flex justify-center mt-10">
                      <ButtonComponent
                        text={`${
                          isButtonDisabled ? "Downloading" : "Download Receipt"
                        }`}
                        icon={
                          isLoadingAPI && (
                            <Lottie
                              className="md:w-5 w-5 h-auto"
                              animationData={CircularLoading}
                              loop={true}
                            />
                          )
                        }
                        disabled={isButtonDisabled}
                        utils={`border border-dealogikal-200 text-dealogikal-200 md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2 hover:bg-dealogikal-200 hover:text-white duration-500 rounded-full font-normal md:w-72 w-full
                           ${
                             isDownloadingPDF
                               ? "cursor-not-allowed opacity-50"
                               : "cursor-pointer"
                           }`}
                        onClick={() => handlePDFDownload("receipt")}
                      />
                    </div>
                    {selectedTransit?.map((item: any, index: any) => (
                      <div
                        className="w-full bg-gray-50 shadow-md rounded-lg px-20 py-5 h-[70rem] "
                        ref={contentRef}
                        key={index}
                      >
                        <div className="flex justify-between items-center mb-5">
                          <img src={logo} alt="logo" className="h-40 w-52" />

                          <div className="text-center flex-1">
                            <h1 className="text-dealogikal-100 font-bold text-xl mb-2">
                              DEALOGIKAL CORP.
                            </h1>
                            <p className="text-sm mb-2">
                              Kiener Energy Centre Bldg., Kiener Hills, Matumbo
                              Road, Pusok, Lapulapu City, Cebu
                            </p>
                            <p className="text-sm mb-2">(032) 411-0303</p>
                            <a
                              href="https://www.dealogikal.com"
                              className="text-blue-500 text-sm"
                            >
                              https://www.dealogikal.com
                            </a>
                          </div>

                          <div className="text-right flex-shrink-0">
                            <button className="font-bold text-lg">
                              Deliver Receipt
                            </button>
                          </div>
                        </div>
                        <div className="mb-8 grid grid-cols-2 gap-4 text-sm lg:text-base w-full">
                          <div>
                            <p className="flex gap-4 items-center">
                              <span className="w-30">Customer Name</span>
                              <span>:</span>
                              <span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
                                {item.buyer_name}
                              </span>
                            </p>
                            <p className="flex gap-4 items-center">
                              <span className="w-30">Address</span>
                              <span>:</span>
                              <span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
                                {item.logisticRate[0].location}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className="flex gap-4 items-center">
                              <span className="w-24">Date</span>
                              <span>:</span>
                              <span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
                                {item.requested_delivery_date}
                              </span>
                            </p>
                          </div>
                        </div>

                        <table className="min-w-full border border-gray-500 text-center my-10">
                          <thead>
                            <tr className="bg-gray-100">
                              <th
                                scope="col"
                                className="py-4 px-6 text-sm font-semibold text-dealogikal-200 border-r border-gray-500"
                              >
                                Quantity Ordered
                              </th>
                              <th
                                scope="col"
                                className="py-4 px-6 text-sm font-semibold text-dealogikal-200 border-r border-gray-500"
                              >
                                Quantity Received
                              </th>
                              <th
                                scope="col"
                                className="py-4 px-6 text-sm font-semibold text-dealogikal-200"
                              >
                                Description
                              </th>
                            </tr>
                          </thead>
                          {item.items.map((items: any) => (
                            <tbody className="bg-white">
                              <tr className="border-t border-gray-500">
                                <td className="py-4 px-6 text-sm text-gray-900 border-r border-gray-500">
                                  {items.ordered_volume}
                                </td>
                                <td className="py-4 px-6 text-sm text-gray-900 border-r border-gray-500"></td>
                                <td className="py-4 px-6 text-sm text-gray-900">
                                  {items.commodity_name}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                        {item.initial_delivery_receipt.length > 0 && (
                          <div className="mt-20 border-t border-gray-500 pt-10">
                            <div className="space-y-6">
                              {/* Access the first item in the array */}
                              {(() => {
                                const firstReceipt =
                                  item.initial_delivery_receipt[0];
                                return (
                                  <div className="grid grid-cols-3 gap-10">
                                    <div className="flex items-center">
                                      <span className="w-48 font-semibold">
                                        Driver's Name and Signature:
                                      </span>
                                      <span className="flex-1 border-b border-gray-600 ml-4">
                                        {firstReceipt.driver_firstname +
                                          " " +
                                          firstReceipt.driver_lastname}
                                      </span>
                                    </div>
                                    <div className="flex items-center">
                                      <span className="w-48 font-semibold">
                                        Plate Number:
                                      </span>
                                      <span className="flex-1 border-b border-gray-600 ml-4">
                                        {firstReceipt.plate_number}
                                      </span>
                                    </div>
                                    <div className="flex items-center">
                                      <span className="w-48 font-semibold">
                                        Checked by:
                                      </span>
                                      <span className="flex-1 border-b border-gray-600 ml-4">
                                        {firstReceipt.checked_by}
                                      </span>
                                    </div>
                                    <div className="flex items-center">
                                      <span className="w-48 font-semibold"></span>
                                      <span className="flex-1"></span>
                                    </div>
                                    <div className="flex items-center">
                                      <span className="w-48 font-semibold">
                                        Seal No:
                                      </span>
                                      <span className="flex-1 border-b border-gray-600 ml-4"></span>
                                    </div>
                                    <div className="flex items-center">
                                      <span className="w-48 font-semibold">
                                        Certified By:
                                      </span>
                                      <span className="flex-1 border-b border-gray-600 ml-4">
                                        {firstReceipt.certified_by}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })()}
                            </div>
                          </div>
                        )}

                        <div className="mt-20 border-t border-gray-500 pt-10">
                          <span>Received the above good order & condition</span>
                        </div>
                        <div className="mt-20 border-t border-gray-500 pt-10">
                          <div className="grid grid-cols-2 gap-10">
                            <div className="flex flex-col items-start">
                              <p className="text-center border-b w-80 border-gray-800 inline-block font-bold">
                                KLYNEA MARTINI S. YAUN
                              </p>
                              <span className="text-center text-sm mt-1 ml-5">
                                (Customer Signature over Printed Name)
                              </span>
                            </div>

                            <div className="flex flex-col items-start">
                              <div className="flex items-center">
                                <span className="w-36 font-semibold">
                                  Date Received:
                                </span>
                                <span className="flex-1 border-b border-gray-600 ml-4"></span>
                              </div>
                              <div className="flex items-center mt-2">
                                <span className="w-36 font-semibold">
                                  Time Received:
                                </span>
                                <span className="flex-1 border-b border-gray-600 ml-4"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-center mt-3">
                      <ButtonComponent
                        text={`${isButtonDisabled ? "Confirmed" : "Confirm"}`}
                        icon={
                          isLoadingAPI && (
                            <Lottie
                              className="md:w-5 w-5 h-auto"
                              animationData={CircularLoading}
                              loop={true}
                            />
                          )
                        }
                        disabled={isButtonDisabled}
                        utils={`border border-dealogikal-200 mb-3 text-dealogikal-200 md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2 hover:bg-dealogikal-200 hover:text-white duration-500 rounded-full font-normal md:w-72 w-full
                           ${
                             isDownloadingPDF
                               ? "cursor-not-allowed opacity-50"
                               : "cursor-pointer"
                           }`}
                        onClick={() => {}}
                      />
                    </div>
                  </>
                )}
              </>
            }
          />
        </>
      }
    />
  );
};

export default MyOrderManagementComp;
