import React, { useEffect, useMemo, useState } from "react";
import SemiFullModalComponent from "../../../../templates/ModalComponent/SemiFullModalComponent";
import BreadcrumbContainerComponent from "../../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import StepsComponent from "../../../../templates/StepsComponent/StepsComponent";
import { useAppDispatch, useAppSelector } from "../../../../../redux/app/hooks";
import {
  getUtilities,
  getVehiclesDetails,
  setUtilityCommodityTypes,
  setVehicleType,
} from "../../../../../redux/functions/storageSlice";
import {
  getCommodityTypes,
  getVehicleTypeAPI,
} from "../../../../../redux/functions/API";
import {
  generateKey,
  getTokenFromLocalStorage,
  manageBreadcrumb,
  manageSteps,
} from "../../../../../redux/functions/function";
import CircleImageComponent from "../../../../templates/CircleImageComponent/CircleImageComponent";
import { useNavigate } from "react-router-dom";
import OptionsType from "../../../../../model/types/OptionsType";
import StepType from "../../../../../model/types/StepType";
import OptionsVehicleType from "../../../../../model/types/OptionsVehicleType";

interface OpenModalComponentProps {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
}

const CommoditySelectionComp: React.FC<OpenModalComponentProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const _token = getTokenFromLocalStorage() ?? "";
  const dispatch = useAppDispatch();

  const utilities = useAppSelector(getUtilities);
  const vehicles = useAppSelector(getVehiclesDetails);
  const navigate = useNavigate();
  const [commodityType, setCommodityType] = useState(utilities.commodityTypes);
  const [fetchingCommodityTypes, setFetchingCommodityTypes] =
    useState<boolean>(true);
  const [fetchingVehicleTypes, setFetchingVehicleTypes] =
    useState<boolean>(true);
  const [updatedSteps, setUpdatedSteps] = useState<StepType[]>([]);
  const [stepStatus, setStepStatus] = useState<string>("commodities_selection");

  const [stepsFromSession, setStepsFromSession] = useState<StepType[]>([]);

  useEffect(() => {
    const str_steps = sessionStorage.getItem("steps");
    str_steps != null && setStepsFromSession(JSON.parse(str_steps));
  }, []);

  const getStepName = (status: string): string => {
    const stepNames: { [key: string]: string } = {
      commodities_selection: "Commodity Selection",
      vehicle_type: "Vehicle Type",
    };

    return stepNames[status] || "Unknown Step";
  };

  useEffect(() => {
    if (fetchingCommodityTypes && commodityType.length === 0) {
      getCommodityTypes().then((data: any) => {
        setFetchingCommodityTypes(false);
        dispatch(setUtilityCommodityTypes(data));
        setCommodityType(data);
      });
    }

    if (fetchingVehicleTypes) {
      getVehicleTypeAPI(_token)
        .then((data: any) => {
          setFetchingVehicleTypes(false);
          const { items } = data.data;
          dispatch(setVehicleType(items));
        })
        .catch((error) => {
          console.error("Failed to fetch vehicle types:", error);
          setFetchingVehicleTypes(false);
        });
    }
  }, [
    dispatch,
    fetchingCommodityTypes,
    fetchingVehicleTypes,
    commodityType.length,
    _token,
  ]);

  // Assuming utilities.commodityTypes and stepStatus are already defined
  const options: OptionsType[] = utilities.commodityTypes.map((i) => ({
    id: i.id,
    name: i.name,
    icon: i.icon,
    href: `/l/vehicle_manager/${stepStatus}`,
  }));

  const vehicle: OptionsVehicleType[] = vehicles.vehicleType.map((i) => ({
    vehicle_type_id: i.vehicle_type_id,
    vehicle_type_name: i.vehicle_type_name,
  }));

  const steps = useMemo(
    () => [
      {
        name: "Commodity Selection",
        href: "/l/vehicle_manager/commodities_selection",
        status: "upcoming",
      },
      {
        name: "Vehicle Type",
        href: "/l/vehicle_manager/vehicle_type",
        status: "upcoming",
      },
      { name: " ", href: " ", status: "upcoming" },
    ],
    []
  );

  useEffect(() => {
    //const defaultOptionId = options.length > 0 ? String(options[0].id) : ""; // I want this part to be  updated from CircleImageComponent as it click
    //const defaultVehicleId = vehicle.length > 0 ? String(vehicle[0].vehicle_type_id) : ""; // I want this part to be  updated  from CircleImageComponent as it click
    const defaultStep = {
      //commodity_id: defaultOptionId,
      //vehicle_type_id: defaultVehicleId,
      name: "Commodity Selection",
      href: "/l/vehicle_manager/commodities_selection",
      status: "current",
    };

    const updateStepsFromSession = (
      sessionSteps: StepType[],
      stepName: string
      //commodityId: string,
      //vehicleTypeId: string
    ) => {
      manageSteps(
        {
          name: stepName,
          href: `/l/vehicle_manager/${stepStatus}`,
          status: "current",
          //commodity_id: commodityId,
          //vehicle_type_id: vehicleTypeId
        },
        sessionSteps
      )
        .then((updated_steps: any) => {
          setUpdatedSteps(updated_steps);
        })
        .catch((err) => {
          console.error("Failed to update steps from session:", err);
        });
    };

    if (stepsFromSession.length !== 0) {
      const stepName = getStepName(stepStatus);
      //updateStepsFromSession(stepsFromSession, stepName, defaultOptionId,defaultVehicleId);
      updateStepsFromSession(stepsFromSession, stepName);
    } else {
      manageSteps(defaultStep, steps)
        .then((updated_steps: any) => {
          setUpdatedSteps(updated_steps);
        })
        .catch((err) => {
          console.error("Failed to manage default steps:", err);
        });
    }
  }, [stepsFromSession, stepStatus, steps]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setStepStatus("commodities_selection"); // Reset step status on close
  };

  return (
    <>
      <SemiFullModalComponent
        isOpen={isModalOpen}
        onCloseHandler={handleModalClose}
        header={
          <>
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle={getStepName(stepStatus)}
              steps={<StepsComponent steps={updatedSteps} />}
            />
          </>
        }
        body={
          <>
            <div className="flex flex-wrap bg-white-500 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
              {getStepName(stepStatus) === "Commodity Selection" &&
                options.map((opt: OptionsType) => (
                  <CircleImageComponent
                    key={generateKey(opt.id.toString())}
                    content={{ id: opt.id, name: opt.name, icon: opt.icon }}
                    event={{
                      onClick: () => {
                        manageBreadcrumb(
                          {
                            name: opt.name,
                            href: opt.href,
                            id: opt.id,
                          },
                          () => navigate(opt.href)
                        );
                        setStepStatus("vehicle_type");
                      },
                    }}
                  />
                ))}
              {getStepName(stepStatus) === "Vehicle Type" &&
                vehicle.map((veh: OptionsVehicleType) => (
                  <>
                    <CircleImageComponent
                      key={generateKey(veh.vehicle_type_id.toString())}
                      content={{
                        id: Number(veh.vehicle_type_id),
                        name: veh.vehicle_type_name,
                        icon: "https://res.cloudinary.com/doehyebmw/image/upload/v1692950374/dealogikal_2.0/resources/logo/trucking-logo_qesgia.png",
                      }}
                      event={{
                        onClick: () => {
                          manageBreadcrumb(
                            {
                              id: Number(veh.vehicle_type_id),
                              name: veh.vehicle_type_name,
                              href: `/l/add_vehicle/${veh.vehicle_type_name}`,
                            },
                            () =>
                              navigate(
                                `/l/add_vehicle/${veh.vehicle_type_name}`
                              )
                          );
                        },
                      }}
                    />
                  </>
                ))}
            </div>
          </>
        }
      />
    </>
  );
};

export default CommoditySelectionComp;
