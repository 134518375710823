import { createContext, useContext, useState, ReactNode } from "react";

// Define the context type
interface TableContextType {
  newRow: any; // Stores the new row data
  setNewRow: (row: any) => void; // Function to update newRow
}

// Create the context
const TableContext = createContext<TableContextType | undefined>(undefined);

// Create a provider component
export const TableProvider = ({ children }: { children: ReactNode }) => {
  const [newRow, setNewRow] = useState<any>(null); // State to hold the new row data

  return (
    <TableContext.Provider value={{ newRow, setNewRow }}>
      {children}
    </TableContext.Provider>
  );
};

// Create a custom hook to use the context
export const useTableContext = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error("useTableContext must be used within a TableProvider");
  }
  return context;
};
