import { useEffect, useState } from "react";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import {
  ClipboardIcon,
  DocumentDuplicateIcon,
  TruckIcon,
  // MagnifyingGlassIcon,
  RectangleGroupIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import TableComponent from "../../../templates/TableComponent/TableComponent";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import jwt_decode from "jwt-decode";
import { TokenModel } from "../../../../model/interface/TokenModel";
import { getAllAddTruckAPI } from "../../../../redux/functions/API";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
  getVehiclesDetails,
  setNextVehicleTemplateName,
} from "../../../../redux/functions/storageSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import VehicleListType from "../../../../model/types/VehicleListType";
import CommoditySelectionComp from "./add_truck/CommoditySelectionComp";

const VehicleListComp: React.FC = () => {
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const dispatch = useAppDispatch();

  const vehiclelistdetail = useAppSelector(getVehiclesDetails);

  // janm: flag for the active nav item
  const [activeNav, setActiveNav] = useState<string>("All");
  const [vehiclesList, setVehiclesList] = useState<VehicleListType[]>(
    vehiclelistdetail.selectedVehicleList
  );
  const [isVehicleListLoading, setIsVehicleListLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  

  const [currentPage, setCurrentPage] = useState<number>(1); // Keep track of the current page
  const [rowsPerPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const totalPages = Math.ceil(vehiclesList.length / rowsPerPage);
  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = vehiclesList.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber: number) => {
    // Ensure page number is within bounds (1 <= pageNumber <= totalPages)
    if (pageNumber < 1) {
      setCurrentPage(1); // Prevent navigating to a page below 1
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages); // Prevent navigating to a page above the totalPages
    } else {
      setCurrentPage(pageNumber);
    }
  };

  // Janm: setup table header columns
  const tableHeader = [
    {
      name: "vehicle",
      value: "Vehicle",
      icon: ClipboardIcon,
    },
    {
      name: "vehicle_type",
      value: "Vehicle Type",
      icon: TruckIcon,
    },
    {
      name: "plate_number",
      value: "Plate Number",
      icon: DocumentDuplicateIcon,
    },
    {
      name: "volume",
      value: "Volume",
      icon: Square3Stack3DIcon,
    },
    {
      name: "commodity",
      value: "Commodity",
      icon: RectangleGroupIcon,
    },
  ];

  const setActiveNavTab = (active_item: string) => {
    setActiveNav(active_item);
  };

  // janm: this array of objects will be passed in the
  // NavTab component for populating the nav items
  const nav_items = [
    {
      text: "All",
    },
    // // {
    // //   text: "Expired",
    // // },
    // // {
    // //   text: "Delisted",
    // },
  ];

  // useEffect(() => {
  //   // if vehicle is empty
  //   if (vehiclesList.length === 0 && isVehicleListLoading) {
  //     const supplier_id: string = userData._id.toString();
  //     getAllAddTruckAPI(_token, supplier_id).then((response: any) => {
  //       setIsVehicleListLoading(false);
  //       console.log("response w/o supplier",response.vehiclesWithoutSupplier);
  //       console.log("response supplier",response.vehiclesWithSupplier);
  //       setVehiclesList(response.data.items);
  //       let nextDefaultVehicleName = `Vehicle Truck ${
  //         response.data.items.length + 1
  //       }`;
  //       // set the next vehicle template if logistic creates new templat
  //       dispatch(setNextVehicleTemplateName(nextDefaultVehicleName));
  //     });
  //   } else {
  //     setIsVehicleListLoading(false);
  //   }
  // }, [userData._id, _token, vehiclesList, isVehicleListLoading, dispatch]);

  useEffect(() => {
    // If vehicle list is empty
    if (vehiclesList.length === 0 && isVehicleListLoading) {
      const supplier_id: string = userData._id.toString();
  
      getAllAddTruckAPI(_token, supplier_id)
        .then((response: any) => {
          setIsVehicleListLoading(false);
  
          // Access the 'items' array from both vehiclesWithoutSupplier and vehiclesWithSupplier
          const vehiclesWithoutSupplier = response.vehiclesWithoutSupplier?.data?.items || [];
          const vehiclesWithSupplier = response.vehiclesWithSupplier?.data?.items || [];
  
          // Combine both vehicle lists
          const combinedVehicles = [...vehiclesWithoutSupplier, ...vehiclesWithSupplier];
  
          // Set the combined vehicle list
          setVehiclesList(combinedVehicles);
          setTotalRecords(combinedVehicles.length);
  
          // Generate the next default vehicle name
          let nextDefaultVehicleName = `Vehicle Truck ${combinedVehicles.length + 1}`;
  
          // Set the next vehicle template name in Redux
          dispatch(setNextVehicleTemplateName(nextDefaultVehicleName));
        })
        .catch((error) => {
          console.error("Error fetching vehicles:", error);
          setIsVehicleListLoading(false);
        });
    } else {
      setIsVehicleListLoading(false);
    }
  }, [userData._id, _token, vehiclesList, isVehicleListLoading, dispatch]);  

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle="Vehicle Manager"
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <NavTabComponent
              key={Math.random()}
              nav_type="primary"
              active_tab={activeNav}
              nav_items={nav_items}
              setActiveNavHandler={setActiveNavTab}
              // left_content={
              //   <div className="relative rounded-md md:py-0 py-2 md:mt-0 mt-2 w-full">
              //     <input
              //       type="text"
              //       name="account-number"
              //       id="account-number"
              //       className="block w-full rounded-3xl border-0 md:px-6 px-4 md:py-4 py-3.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
              //       placeholder="Search Trucks"
              //     />
              //     <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-4 pr-5">
              //       <MagnifyingGlassIcon
              //         className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
              //         aria-hidden="true"
              //       />
              //     </div>
              //   </div>
              // }
              right_content={
                <>
                  {vehiclesList.length > 0 && (
                    <ButtonComponent
                      text="Add Truck"
                      utils="bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:my-0 mb-5 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal md:w-1/3 w-full"
                      onClick={() => setIsModalOpen(true)}
                    />
                  )}
                </>
              }
            />
            <div className="flex flex-wrap justify-center">
              {/* single card (start) */}
              <div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
                {activeNav === "All" && (
                  <>
                    {isVehicleListLoading ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={1} />
                      </div>
                    ) : (
                      <TableComponent
                        tableHeader={tableHeader}
                        tableData={currentItems}
                        currentPage={currentPage}
                        rowsPerPage={rowsPerPage}
                        totalRecords={totalRecords}
                        hasRowClick={true}
                        idStringProperty="vehicle_id"
                        emptyState={{
                          primaryMessage: "No vehicle available.",
                          secondaryMessage:
                            "Get started by creating a new 3pl vehicle.",
                          buttonText: "Add Truck",
                          buttonOnClick() {
                            setIsModalOpen(true)
                          },
                        }}
                        handlePageChange={paginate}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        }
      />
      <CommoditySelectionComp isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
    </>
  );
};

export default VehicleListComp;
