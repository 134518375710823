import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipboardIcon } from "@heroicons/react/24/outline";
//import LogRateListType from "../../../../../model/types/LogRateListType";
import { getRateDetailsAPI, postToAddRowForRates } from "../../../../../redux/functions/API";
import { getTokenFromLocalStorage } from "../../../../../redux/functions/function";
import TableComponentwithRow from "../../../../templates/TableComponent/TableComponentwithRow";
import { useTableContext } from "../../../../../context/TableContext";
import { useAppDispatch } from "../../../../../redux/app/hooks";
import { setCVRate } from "../../../../../redux/functions/storageSlice";
import CVRateTypes from "../../../../../model/types/CVRateTypes";
import { useToasterContext } from "../../../../../context/ToasterContext";


const CVDetailsComponent = () => {
  const { newRow } = useTableContext();
  const { setToastMessage, setToastType, setIsToastOpen } = useToasterContext();
  const dispatch = useAppDispatch();
  const _token = getTokenFromLocalStorage() ?? "";
  //const [rateDetails, setRateDetails] = useState<LogRateListType[]>([]);
  const [rateDetails, setRateDetails] = useState<CVRateTypes[]>([]);
  const [tableHeader, setTableHeader] = useState<any[]>([]);
  const navigate = useNavigate();

  const { rate_details_id } = useParams<string>();

  useEffect(() => {
    getRateDetailsAPI(_token, rate_details_id ?? "").then((response: any) => {
      const data = response.data.items;
      setRateDetails(data);

      // Dynamically generate the table headers based on the response keys
      if (data.length > 0) {
        const keys = Object.keys(data[0]);
        const headers = keys.map((key) => ({
          name: key,
          value: key.replace(/_/g, " ").toUpperCase(), // Format key names for display
          icon: ClipboardIcon, // Use a default icon or map specific icons if needed
        }));
        setTableHeader(headers);
      }
    });
  }, [_token, rate_details_id]);

  useEffect(() => {
    if (!newRow || Object.keys(newRow).length === 0) return; // Exit early if newRow is undefined
  
    const data: any = {
      rate_details_id: newRow?.rate_details_id,
      rates: [
        {
          location: newRow?.location,
          direction: newRow?.direction,
          km: newRow?.distance,
          freight: newRow?.freight,
          pump: newRow?.pump,
          "12 % vat": newRow?.tax,
          total: newRow?.total,
          "new rate": newRow?.rate,
        },
      ],
    };
  
    const postNewRow = async () => {
      try {
        const response: any = await postToAddRowForRates(_token, data);
  
        if (response?.statusCode === 200) {
          setRateDetails((prevDetails) => [...prevDetails, newRow]);
          dispatch(setCVRate(newRow)); // Update state
        } else if (response?.statusCode === 400) {
          console.error("Rate does not exist!");
          setToastMessage("Rate does not exist!");
          setToastType("error");
          setIsToastOpen(true);
        }
      } catch (error: any) {
        console.error("Error in posting rates:", error.message || error);
      }
    };
  
    postNewRow();
  }, [dispatch,newRow, _token,setIsToastOpen,setToastMessage,setToastType]);
  

  return (
    <div className="mt-2">
      <TableComponentwithRow
        tableHeader={tableHeader}
        tableData={rateDetails}
        currentPage={1}
        rowsPerPage={10}
        hasRowClick={true}
        idStringProperty="rate_id" // TODO: change to reverse_auction_id
        emptyState={{
          primaryMessage: "No Data",
          secondaryMessage: "Get started",
          buttonText: "Add Data",
          buttonOnClick() {
            navigate("/l/rates");
          },
        }}
      />
    </div>
  );
};


export default CVDetailsComponent;
