/**
 * TableComponentwithRow is a component that displays a table with sortable columns and a row for adding new data.
 * It also handles showing the new row for input and triggers an API call to add the row.
 * 
 * @param {Object} props - The component's props.
 * @param {Array} props.tableData - The data to display in the table rows.
 * @param {Array} props.tableHeader - The headers to display for the table columns.
 * @param {string} props.idStringProperty - The property name used to uniquely identify each row.
 * @param {function} [props.handleRowClick] - Optional handler for row clicks.
 * @param {Object} [props.emptyState] - Optional empty state data to show when no rows are available.
 * @param {number} props.rowsPerPage - Number of rows per page.
 * @param {number} props.currentPage - The current page number.
 * @param {number} props.totalRecords - The total number of records.
 * @param {function} props.handlePageChange - Function to handle page changes in the table.
 */


import { useState } from "react";
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import EmptyStateComp from "../EmptyStateComponent/EmptyStateComp";
import { TableModel } from "../../../model/interface/TableModel";
import { generateKey } from "../../../redux/functions/function";
import { useTableContext } from "../../../context/TableContext";

export default function TableComponentwithRow(props: TableModel) {
  const [sort, setSort] = useState("");
  const { setNewRow } = useTableContext();
  const [newRowData, setNewRowData] = useState<any>({}); // Tracks input for the new row
  const [showNewRow, setShowNewRow] = useState(false); // Controls visibility of the input row
  const [isRowAdded, setIsRowAdded] = useState(false); // Tracks if the new row has been added or not

  // Sorting logic (unchanged)
  const sortingIconComp = (column: string) =>
    sort === "asc" ? (
      <ChevronUpIcon
        className="cursor-pointer md:h-4 md:w-4 translate-y-1 h-3 w-3 inline float-right"
        onClick={() => manageSorting("desc", column)}
      />
    ) : sort === "desc" ? (
      <ChevronDownIcon
        className="cursor-pointer md:h-4 md:w-4 translate-y-1 h-3 w-3 inline float-right"
        onClick={() => manageSorting("", column)}
      />
    ) : (
      <ChevronUpDownIcon
        className="cursor-pointer md:h-4 md:w-4 translate-y-1 h-3 w-3 inline float-right"
        onClick={() => manageSorting("asc", column)}
      />
    );

  const manageSorting = (sort: string, sortBy: string) => {
    setSort(sort);
  };

  // Handle input change for the new row
  const handleInputChange = (key: string, value: string) => {
    setNewRowData((prev: any) => ({ ...prev, [key]: value }));
  };

  // Add the new row to the table data
  const addNewRow = () => {
    if (!showNewRow) {
      // First click: Just show the new row without triggering the API
      setShowNewRow(true); // Display the input row
    } else {
      // Second click: Trigger API call with new row data
      if (!isRowAdded) {
        // Make sure the API is triggered only once
        setIsRowAdded(true); // Set flag to prevent further triggers

        // Set the new row data in the context (this is your existing logic)
        setNewRow(newRowData);
        setNewRowData({}); // Reset the input field state
        setShowNewRow(false); // Hide the input row after saving
        
        // Trigger the API call (your existing logic for API call)
        // Do not touch your postToAddRowForRates function
      }
      if (isRowAdded) {
        setIsRowAdded(false); // Allow next row to be added
        setNewRowData({}); // Clear the input for the new row
        setShowNewRow(true); // Show input row for the next addition
        setNewRow(newRowData);
      }
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle py-2 sm:py-0">
            {props.tableData.length > 0 ||
            Object.keys(newRowData).length > 0 ? (
              <>
                <div className="overflow-hidden mb-4">
                  <table className="min-w-full divide-y divide-gray-200 rounded-lg border border-gray-300 overflow-hidden">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        {props.tableHeader.map((t) => (
                          <th
                            key={t.name}
                            scope="col"
                            className="py-3.5 pl-4 pr-3 w-96 text-left text-xs font-semibold text-gray-400 border-b border-gray-300"
                          >
                            {t.icon && (
                              <t.icon className="-ml-0.5 mr-1 md:mr-2 md:h-5 md:w-5 h-3 w-3 inline" />
                            )}
                            <span className="align-middle">{t.value}</span>
                            {sortingIconComp(t.name)}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {props.tableData.map((row: any) => (
                        <tr
                          key={row[props.idStringProperty!] || generateKey(row.name)}
                          className={`divide-x divide-gray-200 hover:bg-gray-50 ${props.hasRowClick ? "cursor-pointer" : ""} border-b border-gray-300`}
                          onClick={() => {
                            props.handleRowClick &&
                              props.handleRowClick!(row[props.idStringProperty!]);
                          }}
                        >
                          {props.tableHeader.map((col) => (
                            <td
                              key={col.name}
                              className="whitespace-nowrap py-2 md:py-2 pl-4 pr-3 md:text-base text-sm"
                            >
                              <div
                                className={`${col.isTruncated ? "w-80 truncate" : ""}`}
                              >
                                {col.customRender
                                  ? col.customRender(row)
                                  : row[col.name]}
                              </div>
                            </td>
                          ))}
                        </tr>
                      ))}
                      {showNewRow && (
                        <tr className="divide-x divide-gray-200 bg-gray-50">
                          {props.tableHeader.map((col) => (
                            <td
                              key={col.name}
                              className="px-0 py-0 border-b border-gray-300"
                            >
                              <input
                                type="text"
                                className="border border-gray-300 px-1 py-1 w-full"
                                placeholder={`Enter ${col.value}`}
                                value={newRowData[col.name] || ""}
                                onChange={(e) =>
                                  handleInputChange(col.name, e.target.value)
                                }
                              />
                            </td>
                          ))}
                        </tr>
                      )}
                      <tr className="bg-white">
                        <td
                          colSpan={props.tableHeader.length}
                          className="h-4 border-b-0 pb-5 border-t border-gray-300"
                        >
                          <button
                            className="text-gray-700 bg-gray-100 hover:bg-gray-200 px-1 py-1 rounded border border-gray-300"
                            onClick={addNewRow}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="h-6 w-6 inline-block mr-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                            Add Row
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <PaginationComponent
                  rowsPerPage={props.rowsPerPage}
                  currentPage={props.currentPage}
                  totalRecords={props.totalRecords}
                  totalRows={props.tableData.length}
                  onPageChange={props.handlePageChange}
                />
              </>
            ) : (
              props.emptyState && (
                <EmptyStateComp
                  hasCallToAction={props.emptyState?.hasCallToAction}
                  icon={
                    <PlusCircleIcon
                      className="h-10 w-10 mx-auto text-gray-300 mt-16"
                      aria-hidden="true"
                    />
                  }
                  primary_message={props.emptyState?.primaryMessage}
                  secondary_message={props.emptyState?.secondaryMessage}
                  button_text={props.emptyState?.buttonText}
                  button_onclick_handler={props.emptyState?.buttonOnClick}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
