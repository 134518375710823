import React, { useEffect, useRef, useState } from "react";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/app/hooks";
import {
	getOfferBookingId,
	getReverseAuctionDetails,
	getReverseAuctionList,
} from "../../../../redux/functions/storageSlice";
import {
	ArrowUpTrayIcon,
	DocumentIcon,
	PhotoIcon,
	XCircleIcon,
} from "@heroicons/react/24/outline";
import {
	getTokenFromLocalStorage,
	manageSteps,
} from "../../../../redux/functions/function";
import CircularLoading from "../../../../lottie/circular_loading_theme_1.json";
import StepType from "../../../../model/types/StepType";
import StepsComponent from "../../../templates/StepsComponent/StepsComponent";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import ToasterComponent from "../../../templates/ToasterComponent/ToasterComponent";
import {
	getBuyerOrderDetailsAPI,
	uploadImageReceipt,
} from "../../../../redux/functions/API";
import ModalComponent from "../../../templates/ModalComponent/ModalComponent";
import OrderDetailsType from "../../../../model/types/OrderDetailsType2";
import Lottie from "lottie-react";
// import { zIndex } from "html2canvas/dist/types/css/property-descriptors/z-index";

const BookedOrderDetailsComp = () => {
	const _token = getTokenFromLocalStorage() ?? "";
	const navigate = useNavigate();

	const cloudName = "doehyebmw";
	const uploadPreset = "zjo09rbn";

	const offerBookingId = useAppSelector(getOfferBookingId);

	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [error, setError] = useState<string>("");
	const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [success, setSuccess] = useState<string>("");

	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isUploadingReceipt, setIsUploadingReceipt] = useState<boolean>(false);
	const [isLoadingAPI, setIsLoadingAPI] = useState(false);

	const { reverse_auction_id } = useParams();
	const reverseAuctionList = useAppSelector(getReverseAuctionList);
	const reverseAuctionDetails = useAppSelector(getReverseAuctionDetails);
	const auction_id = Number(reverse_auction_id); // Convert to number if needed

	// toast message (start) ================================================>
	const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>("");
	const [toastType, setToastType] = useState<string>("");
	// toast message (end) ================================================>

	// check if offerBookingId if it's empty or not
	useEffect(() => {
		if (offerBookingId === 0) {
			navigate("/b/reverse_auction_list");
		}
	}, [offerBookingId, navigate]);

	const [isBuyerOrderDetailsFetching, setIsBuyerOrderDetailsFetching] =
		useState<boolean>(true);
	const [orderDetailsList, setOrderDetailsList] =
		useState<OrderDetailsType>();
	useEffect(() => {
		if (isBuyerOrderDetailsFetching === true) {
			const offer_booking_id = offerBookingId;
			const order_status_id = 3;
			getBuyerOrderDetailsAPI(_token, auction_id, offer_booking_id, order_status_id).then(
				(response: any) => {
					if (response.statusCode === 200) {
						setIsBuyerOrderDetailsFetching(false);
						setOrderDetailsList(response.data.items);
					}
				}
			);
		}
	});
	// const supplierLogisticDetails = (list: any) => [
	// 	<p>
	// 		<span style={{ fontWeight: 'bold', color: 'black' }}>Supplier Name:</span>

	// 		{
	// 			reverseAuctionDetails.supplier_data.cheapestSupplier.supplier_details
	// 				.company_name
	// 		}
	// 	</p>,
	// 	<p>
	// 		<span style={{ fontWeight: 'bold', color: 'black' }}>Logistic Name:</span> {list.logisticName}
	// 	</p>,
	// ];
	// const commoditiesDetails = orderDetailsList?.map((list) => (
	// 	<>
	// 		{/* Replace dummy content with actual content from list as necessary */}
	// 		<div className="flex flex-col justify-between h-full">
	// 			<div>
	// 				<p className="mb-1">
	// 					<strong>Product Name: </strong>
	// 				</p>

	// 				{Array.isArray(list.items) && list.items.map((item, index) => {
	// 					// Calculate total for each product (ordered_volume * booking_price)
	// 					const productTotal = (item?.ordered_volume || 0) * (item?.booking_price || 0);

	// 					// Show Product Details with commodity name and totals aligned to the right
	// 					return (
	// 						<div key={index} className="flex justify-between mb-2">
	// 							{/* Left side: Product info (commodity name + volume x price) */}
	// 							<div className="flex-1">
	// 								<p className="mb-1">
	// 									<strong>{item?.commodity_name}</strong><br />
	// 									{item?.ordered_volume.toLocaleString() || 1000} x{" "}
	// 									{item?.booking_price || "----"}
	// 								</p>
	// 							</div>
	// 							{/* Right side: Product Total */}
	// 							<div className="text-right">
	// 								<p className="mb-1">
	// 									₱ {productTotal.toLocaleString() || "----"}
	// 								</p>
	// 							</div>
	// 						</div>
	// 					);
	// 				})}
	// 				<hr />
	// 			</div>

	// 			{/* Summary Section for Total Calculation */}
	// 			<div className="mt-4">
	// 				{/* Total for all products */}
	// 				<div className="flex justify-between mb-1">
	// 					<p className="flex-1">Product Total:</p>
	// 					<p className="text-right">
	// 						₱{" "}
	// 						{Array.isArray(list.items) &&
	// 							list.items
	// 								.reduce((total, item) => {
	// 									return total + (item?.ordered_volume || 0) * (item?.booking_price || 0);
	// 								}, 0)
	// 								.toLocaleString() || "----"}
	// 					</p>
	// 				</div>

	// 				{/* Delivery Fee for all items */}
	// 				<div className="flex justify-between mb-1">
	// 					<p className="flex-1">Delivery Fee:</p>
	// 					<p className="text-right">
	// 						₱{" "}
	// 						{Array.isArray(list.items) &&
	// 							list.items
	// 								.reduce((total, item) => {
	// 									const rate = list.logisticRate?.[0]?.rate || 0;
	// 									return total + (item?.ordered_volume || 0) * rate;
	// 								}, 0)
	// 								.toLocaleString() || "----"}
	// 					</p>
	// 				</div>

	// 				{/* Processing Fee */}
	// 				<div className="flex justify-between mb-1">
	// 					<p className="flex-1">Processing Fee:</p>
	// 					<p className="text-right">
	// 						{" "}
	// 						{Array.isArray(list.items) && list.items.length > 0 ? (
	// 							(
	// 								list.items.reduce((total, item) => {
	// 									return total + (item?.ordered_volume || 0) * (item?.booking_price || 0);
	// 								}, 0) +
	// 								list.items.reduce((total, item) => {
	// 									const rate = list.logisticRate?.[0]?.rate || 0;
	// 									return total + (item?.ordered_volume || 0) * rate;
	// 								}, 0)
	// 							) *
	// 							1.5 /
	// 							100 // Multiply by 1.5 and divide by 100 to get the processing fee
	// 						).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
	// 							"NA"}
	// 					</p>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</>
	// ));

	// const totalDetails = orderDetailsList?.map((list) => (
	// 	<div className="flex justify-between w-full items-center">
	// 		<div className="flex-grow text-center">
	// 			<strong>Total amount:</strong>
	// 		</div>
	// 		<div
	// 			className="text-right">
	//     ₱{" "}
	// {Array.isArray(list.items) && list.items.length > 0 ? (
	//   // Calculate the Total Amount as (Total + Delivery Fee + Processing Fee)
	//   (
	//     // Calculate Total
	//     list.items.reduce((total, item) => {
	//       return total + (item?.ordered_volume || 0) * (item?.booking_price || 0);
	//     }, 0) +

	//     // Calculate Delivery Fee
	//     list.items.reduce((total, item) => {
	//       const rate = list.logisticRate?.[0]?.rate || 0;
	//       return total + (item?.ordered_volume || 0) * rate;
	//     }, 0) +

	//     // Calculate Processing Fee (PF) = (Total + Delivery Fee) * 1.5 / 100
	//     (
	//       list.items.reduce((total, item) => {
	//         return total + (item?.ordered_volume || 0) * (item?.booking_price || 0);
	//       }, 0) +
	//       list.items.reduce((total, item) => {
	//         const rate = list.logisticRate?.[0]?.rate || 0;
	//         return total + (item?.ordered_volume || 0) * rate;
	//       }, 0)
	//     ) *
	//       1.5 /
	//       100
	//   ) // Add Total, Delivery Fee, and PF
	//   .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
	// ) : (
	//   "NA"
	// )}
	//   </div>
	// 	</div>
	// ));

	// Get the price based on the commidity id and volume 
	const getPrice = (commodityId: number, volume: number) => {
		const matchingOffer = orderDetailsList?.supplier_data.cheapestSupplier.offers.find(
			(offer: { commodity_id: number; minimum_quantity: number; maximum_quantity: number; }) => offer.commodity_id === commodityId &&
				volume >= offer.minimum_quantity &&
				volume <= offer.maximum_quantity
		);
		return matchingOffer ? matchingOffer.price : 0;
	};

	// Holds the total product price
	let totalProductCost = 0;
	let totalProductVolume = 0;
	let totalDeliveryFee = 0;
	let totalProcessingFee = 0;


	const [stepsFromSession, setStepsFromSession] = useState<StepType[]>([]);
	useEffect(() => {
		const str_steps = sessionStorage.getItem("steps");
		str_steps != null && setStepsFromSession(JSON.parse(str_steps));
	}, []);

	const [updatedSteps, setUpdatedSteps] = useState<StepType[]>([]);
	useEffect(() => {
		if (stepsFromSession.length !== 0) {
			manageSteps(
				{ name: "Payment", href: "/payment", status: "current" },
				stepsFromSession
			).then((updated_steps: any) => {
				setUpdatedSteps(updated_steps);
			});
		}
	}, [stepsFromSession]);

	// import and upload (start) ==============================================================>

	const importImageHandler = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files || []);
		handleFiles(files);
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(false);

		const files = Array.from(event.dataTransfer.files);
		handleFiles(files);
	};

	const handleFiles = (files: File[]) => {
		let totalSize = 0;
		files.forEach((file) => {
			totalSize += file.size;
		});

		const maxSize = 5 * 1024 * 1024; // 5MB in bytes
		if (totalSize > maxSize) {
			setError("Total file size exceeds 5MB. Please upload smaller files.");
			return;
		}

		setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
		setError(""); // Reset error if the file size is within the limit
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(true);
	};

	const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(false);
	};

	// handler for remove chip
	const removeChipHandler = (index: number) => {
		const updatedFiles = [...selectedFiles];
		updatedFiles.splice(index, 1);
		setSelectedFiles(updatedFiles);
	};

	// handler for showing image
	const showImageImportHandler = (index: number) => {
		setIsModalOpen(true);
		const file = selectedFiles[index];
		const reader = new FileReader();
		reader.onloadend = () => {
			setSelectedImage(reader.result as string);
		};
		reader.readAsDataURL(file);
	};

	// Function to close selectedImage
	const closeSelectedImage = () => {
		setSelectedImage(null);
		setIsModalOpen(false);
	};

	// handle api for uploading receipt
	const handlePaymentReceipt = async (data: any) => {
		try {
			const response: any = await uploadImageReceipt(_token, data);
			setIsLoadingAPI(false);
			setIsButtonDisabled(false);
			setIsUploadingReceipt(false);
			if (response.statusCode === 200) {
				setSuccess("Successfully uploaded receipt");
				setToastMessage("Successfully uploaded receipt");
				setToastType("success");
				setIsToastOpen(true);
				setTimeout(() => {
					setIsToastOpen(false);
				}, 3000);
			} else if (response.statusCode === 400) {
				setToastMessage("Unsuccessful upload receipt");
				setToastType("error");
				setIsToastOpen(true);
				setTimeout(() => {
					setIsToastOpen(false);
				}, 3000);
			}
		} catch { }
	};

	// handler for uploading receipt
	const uploadImageHandler = async () => {
		if (selectedFiles.length > 1) {
			setToastMessage("Please upload one receipt only");
			setToastType("error");
			setIsToastOpen(true);
			setTimeout(() => {
				setIsToastOpen(false);
			}, 3000);
			return;
		}
		setIsLoadingAPI(true);
		setIsButtonDisabled(true);
		setIsUploadingReceipt(true);
		const formData = new FormData();
		formData.append("file", selectedFiles[0]);
		formData.append("upload_preset", uploadPreset); // Your upload preset from Cloudinary

		try {
			const response = await fetch(
				`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
				{
					method: "POST",
					body: formData,
				}
			);
			const dataImageUpload = await response.json();

			if (dataImageUpload.error) {
				setError(dataImageUpload.error.message);
			} else {
				setSelectedFiles([]); // Clear the selected files after upload

				const data = {
					offer_bookings_id: offerBookingId,
					deposite_slip_url: dataImageUpload.url,
				};

				await handlePaymentReceipt(data);
			}
		} catch (error) {
			setError("Error uploading to Cloudinary");
		}
	};

	// import and upload (end) ==============================================================>

	return (
		<MainConsoleLayoutComponent
			content={
				<>
					<ToasterComponent
						isOpen={isToastOpen}
						label={toastMessage}
						onClose={setIsToastOpen}
						type={toastType}
					/>

					<BreadcrumbContainerComponent
						key={Math.random()}
						subtitle="Order Details"
						steps={<StepsComponent steps={updatedSteps} />}
					/>
					<div className="grid lg:grid-cols-2 gap-4 grid-cols-1">
						{/* First column */}
						<div className="col-span-1">
							<div className="">
								<span className="text-sm">
									{orderDetailsList && orderDetailsList.order_details.order_number}
								</span>
							</div>

							{/* Display formatted details */}
							<div className="mt-10 border-b w-full h-30 p-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-700 whitespace-pre-line">
								<p key="shipping_method">
									<span style={{ fontWeight: 'bold', color: 'black' }}>Shipping Method:</span>{" "}
									<span>{orderDetailsList?.reverse_auction_data.shipping_method}</span>
								</p>
								<p key="delivery_location">
									<span style={{ fontWeight: 'bold', color: 'black' }}>Delivery Location:</span>{" "}
									<span>
										{reverseAuctionDetails.reverse_auction_data.delivery_location}
									</span>
								</p>
								<p key="delivery_date">
									<span style={{ fontWeight: 'bold', color: 'black' }}>Delivery Date</span>{" "}
									<span>{orderDetailsList?.reverse_auction_data.delivery_date}</span>
								</p>
							</div>
							<div className="mt-8 border-b w-full h-30 p-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-700 whitespace-pre-line">
								<p>
									<span style={{ fontWeight: 'bold', color: 'black' }}>Supplier Name: </span>
									{orderDetailsList?.supplier_data.cheapestSupplier.supplier_details.company_name}
								</p>
								<p>
									<span style={{ fontWeight: 'bold', color: 'black' }}>Logistic Name: </span>
									{orderDetailsList?.logistic_data.cheapestLogistic.logistic_details.company_name}
								</p>
							</div>
							<div className="mt-8 border-b w-full p-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-700">
								{/* Replace dummy content with actual content from list as necessary */}
								<div className="flex flex-col justify-between h-full">
									<div>
										<p className="mb-1">
											<strong>Product Name: </strong>
										</p>

										{Array.isArray(orderDetailsList?.buyer_data.commodities) &&
											orderDetailsList?.buyer_data.commodities.map((item, index) => {
												// Calculate total for each product (ordered_volume * booking_price)
												const price = getPrice(item.commodity_id, item.volume);
												const volume = item?.volume;
												const productTotal = volume * price;
												totalProductCost += productTotal;
												totalProductVolume += volume;
												totalDeliveryFee = totalProductVolume * (orderDetailsList?.logistic_data.cheapestLogistic.rate || 0);
												totalProcessingFee = (totalProductCost
													+ (totalProductVolume * (orderDetailsList?.logistic_data.cheapestLogistic.rate || 0)))
													* 1.5 / 100; // Multiply by 1.5 and divide by 100 to get the processing fee

												// Show Product Details with commodity name and totals aligned to the right
												return (
													<div key={index} className="flex justify-between mb-2">
														{/* Left side: Product info (commodity name + volume x price) */}
														<div className="flex-1">
															<p className="mb-1">
																<strong>{item?.commodity_name}</strong><br />
																{volume} x {" "}
																{price}
															</p>
														</div>
														{/* Right side: Product Total */}
														<div className="text-right">
															<p className="mb-1">
																₱ {productTotal.toLocaleString()}
															</p>
														</div>
													</div>
												);
											})}
										<hr />
									</div>
								</div>
								{/* Summary Section for Total Calculation */}
								<div className="mt-4">
									{/* Total for all products */}
									<div className="flex justify-between mb-1">
										<p className="flex-1">Product Total:</p>
										<p className="text-right">
											₱ {totalProductCost.toLocaleString()}
										</p>
									</div>

									{/* Delivery Fee for all items */}
									<div className="flex justify-between mb-1">
										<p className="flex-1">Delivery Fee:</p>
										<p className="text-right">
											₱ {totalDeliveryFee.toLocaleString()}
										</p>
									</div>

									{/* Processing Fee */}
									<div className="flex justify-between mb-1">
										<p className="flex-1">Processing Fee:</p>
										<p className="text-right">
											₱ {totalProcessingFee.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
										</p>
									</div>
								</div>
							</div>
							<div
								className="mt-4 border-b w-full h-30 p-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-700"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<strong>Grand Total: </strong> ₱ {(totalDeliveryFee + totalProcessingFee + totalProductCost).toLocaleString()}
							</div>

						</div>
						{/* Second column */}
						<div
							className={`col-span-1 mt-16 ${isDraggingOver ? "bg-gray-200" : ""
								}`}
							onDrop={handleDrop}
							onDragOver={handleDragOver}
							onDragLeave={handleDragLeave}
						>
							<div className="flex flex-col items-center justify-center w-full px-10 py-5 md:w-4/5 mx-auto border border-dashed border-gray-400 rounded-md">
								<div className="col-span-1 flex flex-col items-center justify-center">
									<div
										className={`h-auto self-center rounded-full bg-dealogikal-30 p-8 `}
									>
										<DocumentIcon
											className="h-10 w-10 text-dealogikal-50 rounded-2xl bg-dealogikal-30"
											aria-hidden="true"
										/>
									</div>
									<h3>Drag and drop</h3>
									<p className="text-sm">
										your images here or{" "}
										<button
											className="text-blue-400"
											onClick={importImageHandler}
										>
											upload image...
										</button>
									</p>
									{selectedFiles.length === 0 ? (
										<span className="text-gray-400 md:text-xs text-xxs mt-5">
											-- No file selected --
										</span>
									) : (
										<div className="flex gap-4 content-center items-center overflow-x-auto lg:w-96 w-56 py-3 ">
											{selectedFiles.map((file, index) => (
												<div key={index}>
													<div
														className="relative flex items-center mt-2 bg-gray-100 px-4 py-3 rounded-md hover:bg-dealogikal-40 cursor-pointer"
														onClick={() => showImageImportHandler(index)}
													>
														<PhotoIcon className="h-4 w-4 text-gray-700 mr-2" />
														<span className="text-xs text-gray-700 truncate ... w-16">
															{file.name}
														</span>
														<button
															className="absolute -top-1 -right-1 p-1 bg-dealogikal-300 text-white rounded-full cursor-pointer"
															onClick={(e) => {
																e.stopPropagation();
																removeChipHandler(index);
															}}
														>
															<XCircleIcon className="h-3 w-3" />
														</button>
													</div>
												</div>
											))}
										</div>
									)}
								</div>
								<div className="my-1">
									<input
										type="file"
										ref={fileInputRef}
										style={{ display: "none" }}
										accept=".jpg, .jpeg, .png"
										multiple
										onChange={handleFileChange}
									/>
								</div>
								<span className="text-xs text-gray-500 mt-8">
									Max size: 5MB
								</span>{" "}
								{error && (
									<span className="text-xs text-red-500 mt-2">{error}</span>
								)}
								{success && (
									<span className="text-xs text-green-500 mt-2">{success}</span>
								)}
								{/* Added this wrapping div */}
								{selectedFiles.length !== 0 && (
									<ButtonComponent
										text={`${isButtonDisabled ? "Uploading ..." : "Upload"}`}
										icon={
											isLoadingAPI && (
												<Lottie
													className="md:w-5 w-5 h-auto"
													animationData={CircularLoading}
													loop={true}
												/>
											)
										}
										disabled={isButtonDisabled}
										utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
                        hover:bg-dealogikal-200 cursor-pointer duration-500 rounded-full font-normal md:w-11/12 w-full
                        ${isUploadingReceipt
												? "cursor-not-allowed opacity-50"
												: ""
											}
                        `}
										onClick={uploadImageHandler}
									/>
								)}
								<ModalComponent
									isXL={true}
									isOpen={isModalOpen}
									onCloseHandler={() => setIsModalOpen(false)}
									header={
										<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
											<ArrowUpTrayIcon
												className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
												aria-hidden="true"
											/>
											<span className="text-white inline md:text-base text-xs">
												Import Image
											</span>
										</div>
									}
									body={
										<>
											{selectedImage && (
												<div className="flex justify-center py-5 mx-7 my-4">
													<img
														src={selectedImage}
														alt="Selected"
														className="max-w-full h-96 relative"
													/>
													<button
														onClick={closeSelectedImage}
														className="absolute top-16 right-2 px-3 py-2 text-sm bg-dealogikal-200 text-white rounded-lg cursor-pointer"
													>
														Close
													</button>
												</div>
											)}
										</>
									}
								/>
							</div>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default BookedOrderDetailsComp;
