import React, { createContext, useContext, useState } from 'react';

// Define the shape of the context state
interface ToasterContextType {
  isToastOpen: boolean;
  toastMessage: string;
  toastType: string;
  setIsToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setToastMessage: React.Dispatch<React.SetStateAction<string>>;
  setToastType: React.Dispatch<React.SetStateAction<string>>;
}

// Create the Toaster context with default values
const ToasterContext = createContext<ToasterContextType | undefined>(undefined);

// Explicitly define the children prop
interface ToasterProviderProps {
  children: React.ReactNode;
}

// The provider component that will wrap your application
export const ToasterProvider: React.FC<ToasterProviderProps> = ({ children }) => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('info'); // Default to info

  return (
    <ToasterContext.Provider
      value={{
        isToastOpen,
        setIsToastOpen,
        toastMessage,
        setToastMessage,
        toastType,
        setToastType,
      }}
    >
      {children}
    </ToasterContext.Provider>
  );
};

// Custom hook to access the Toaster context
export const useToasterContext = (): ToasterContextType => {
  const context = useContext(ToasterContext);
  if (!context) {
    throw new Error('useToasterContext must be used within a ToasterProvider');
  }
  return context;
};
