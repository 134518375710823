import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks"
import { getNextRateTemplateName, getVehiclesDetails, setNextRateTemplateName, setSelected_Vehicles } from "../../../../redux/functions/storageSlice"
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent"
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent"
import { CubeIcon, MagnifyingGlassIcon, RectangleStackIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import SelectedVehicleType from "../../../../model/types/SelectedVehicleType";
import ChipsComponent from "../../../templates/ChipsComponent/ChipsComponent";
import { generateKey, getTokenFromLocalStorage } from "../../../../redux/functions/function";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import ModalComponent from "../../../templates/ModalComponent/ModalComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import VehicleListType from "../../../../model/types/VehicleListType";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { getAllAddTruckAPI,getVehicleNames } from "../../../../redux/functions/API";

const SetDetailsComp = () => {
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createRateFields = useAppSelector(getVehiclesDetails);
  const nextRateTemplateName = useAppSelector(getNextRateTemplateName);

  const [isTitleInEditState, setisTitleInEditState] = useState<boolean>(false);
  const [isVehicleListLoading, setIsVehicleListLoading] = useState<boolean>(true);
  const [vehicleList, setVehicleList] = useState<VehicleListType[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<SelectedVehicleType[]>([]);
  const [selectedVehicleID, setSelectedVehicleID] = useState<number[]>([]);
  const [isDisplaySelectionError, setIsDisplaySelectionError] = useState<boolean>(false);
  const [isDeselectVehicleError, setIsDeselectVehicleError] = useState<boolean>(false);
  const [newSubTitle, setNewSubTitle] = useState<string>(
    nextRateTemplateName
  );
  const templateNameIconClickHandler = () => {
    setisTitleInEditState(!isTitleInEditState);
  }

  // useEffect(() => {
  //   if (newSubTitle === "") {
  //     navigate("/l/rates");
  //   }
  // }, [navigate, newSubTitle]); // commented by Rey Montero , The Adding Rates that I add from the vehicle  
  
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // janm: flag for the active nav vehicle
  const [activeSelectVehicle, setActiveSelectVehicle] = useState<string>("Items");
  // janm: this array of objects will be passed in the
  // NavTab component for populating the nav items
  const selectVehicleItems = [
    {
      text: "Vehicles",
    },
    // {
    //   text: "Unlisted(0)",
    // },
  ]

  const removeProductChipHandler = (vehicle_id: number) => {
    removeVehicle(vehicle_id).then((new_selected_arr) => {
      dispatch(setSelected_Vehicles(JSON.stringify(new_selected_arr)));
    });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // janm: vehicle list inside modal checkbox handler
  const selectionHandler = (vehicle_id: number) => {
    const isSelected = selectedVehicleID.includes(vehicle_id);
      if (!isSelected && selectedVehicle.length < 1) {
        const selected = vehicleList.find(vehicle => vehicle.vehicle_id === vehicle_id);
        if (selected) {
          setSelectedVehicle([...selectedVehicle, { vehicle: selected, fields: "" }]);
          setSelectedVehicleID([...selectedVehicleID, vehicle_id]);
        }
       } else if (isSelected) {
        
        deselectErrorHandler();
       } else { 
        selectionErrorHandler();
      };   
};

    // janm: confirm selection handler function
  const confirmSelectionHandler = () => {
     const mergedSelection = [...JSON.parse(createRateFields.selectedVehicles), ...selectedVehicle];
    // declare the base property which will be the basis in sorting the array in alphabet order
    sortSelectedVehicles(selectedVehicle, "alphabet");
    dispatch(setSelected_Vehicles(JSON.stringify(mergedSelection)));
    setIsModalOpen(false);

    // every click of confirm, empty the selected local state
    setSelectedVehicle([]);
  };

  // rlm
  const setDeliveryZoneHandler = () => {
    if (newSubTitle){
       dispatch(setNextRateTemplateName(newSubTitle));
    navigate("/l/set_delivery_zone")
    }
   
  };
  
  // janm: useEffect hook that extracts the product ids
  // and store  at an array if changes detected
  // useEffect hook Purpose: to check the data from redux storage
  // if no data, it means, page was being refresh,
  useEffect(() => {
    if(createRateFields.selectedVehicles === "") {
      navigate("/l/rates");
    } else {
     let newVehicleIDArray: number[] = [];
      JSON.parse(createRateFields.selectedVehicles).forEach((selected_vehicle: SelectedVehicleType) => {
     newVehicleIDArray = [...newVehicleIDArray, selected_vehicle.vehicle.vehicle_id]; 
    });
    setSelectedVehicleID(newVehicleIDArray); 
    }
  }, [navigate, createRateFields.selectedVehicles]); 
  
  const sortSelectedVehicles = (
    arr: SelectedVehicleType[],
    base_type: string
  ) => {
    arr.sort(function (a: any, b: any) {
      var result = 0;
      if (base_type === "alphabet") {
        var textA = a.vehicle.vehicle.toString().toLowerCase();
        var textB = b.vehicle.vehicle.toString().toLowerCase();
        result = textA < textB ? -1 : textA > textB ? 1 : 0;
      } else if (base_type === "numeric") {
        // to be enhance
      }
      return result;
    });
  };

  // Janm: function that handles the deselect vehicle of the error message 
  const deselectErrorHandler = () => {
    setIsDeselectVehicleError(true);
    setTimeout(() => {
      setIsDeselectVehicleError(false);
    }, 3000)
  }
  // janm: function that handles the display of the error message in
  // in selecting products beyond 3
  const selectionErrorHandler = () => {
    setIsDisplaySelectionError(true);
    setTimeout(() => {
      setIsDisplaySelectionError(false);
    }, 3000);
  };
  console.log(createRateFields);
  // janm: useEffect hook to check the vehicle list from redux storage,
  // if empty, then call from api
  useEffect(() => {
    
      // get and decode the token from local storage
      const user_id: string = userData._id.toString();
      

      getVehicleNames(_token, user_id).then((response: any) => {
        // dispatch(setSelectedVehicleList(data));
        setVehicleList(response.data.items);
        setIsVehicleListLoading(false);
      });
     
  }, [_token, userData._id, isVehicleListLoading]);
  
  // janm: remove chips function
  const removeVehicle = (vehicle_id: number) => {
    return new Promise((resolve, reject) => {
      let updatedSelectedItemsArray: SelectedVehicleType[] = [];
      createRateFields.selectedVehicles !== "" &&
      JSON.parse(createRateFields.selectedVehicles).forEach(
        (selected_vehicle: SelectedVehicleType) => {
          if (selected_vehicle.vehicle.vehicle_id !== vehicle_id) {
            updatedSelectedItemsArray = [
              ...updatedSelectedItemsArray,
              selected_vehicle,
            ];
          }
        }
      );
      resolve(updatedSelectedItemsArray);
    });
  };

  const subtitleOnBlurHandler = (new_title: string) => {
    if (new_title.trim() !== "") {
      setNewSubTitle(new_title);
    } else {
      setNewSubTitle(nextRateTemplateName);
    }
  };
  
  return (
    <>
      <MainConsoleLayoutComponent 
        content={
          <>
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <BreadcrumbContainerComponent 
            key={Math.random()}
            subtitle={newSubTitle}
            isTitleEditable={true} // indicated when the page title is editable
            isTitleInEditState={isTitleInEditState} // indication if show input textbox or not; by default set to false to hide input textbox
            titleIconClickHandler={templateNameIconClickHandler}
            subtitleOnBlurHandler={subtitleOnBlurHandler}
          />
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          
          <label className="block text-lg font-medium leading-6 text-gray-900 mt-6">
            <div className="flex gap-2">
              <CubeIcon className="-ml-0.5 h-5 w-5" />
              <span>Selected Vehicles</span>
            </div>
          </label>

          <div className="items-center content-center mt-2 gap-2 w-11/12 md:w-7/12 lg:w-6/12 ">
            <div className="grid md:grid-cols-2">
            {createRateFields.selectedVehicles !== "" &&
              JSON.parse(createRateFields.selectedVehicles).map(
                (selected_vehicle: SelectedVehicleType) => { 
                  return (
                    <ChipsComponent 
                      key={generateKey(
                        Math.random.toString() +
                        selected_vehicle.vehicle.vehicle_id
                      )}
                      text={`PlateNumber:${selected_vehicle.vehicle.plate_number} Vehicletype:${selected_vehicle.vehicle.vehicle_type_name}`}
                      removeHandler={() => {
                        removeProductChipHandler(
                          selected_vehicle.vehicle.vehicle_id
                        )
                      }}
                    />
                  );
                }
              )}
              </div>
              <span className="flex justify-center mt-2">
                <PlusCircleIcon 
                  className="h-12 w-16 text-dealogikal-100"
                  onClick={toggleModal}
                />
              </span>
          </div>
          <div className="flex flex-col mt-24 md:flex-row gap-5 md:justify-center  md:w-2/4 md:mt-56">
                <ButtonComponent
                  text="Cancel Rate Details"
                  utils="bg-gray-400 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:mt-7 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full"
                />
                <ButtonComponent
                  text="Set Delivery Zone"
                  utils="bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:mt-7 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full"
                  onClick={setDeliveryZoneHandler}
                />
          </div>    

           <ModalComponent 
            isOpen={isModalOpen}
            onCloseHandler={() => setIsModalOpen(false)}
            header={
              <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
                <RectangleStackIcon
                  className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
                  aria-hidden="true"
                />
                <span className="text-white inline md:text-base text-xs">
                  Select Vehicle Type
                </span>
              </div>
            }
            body={
              <>
                <div className="relative rounded-md md:py-0 md:px-0 px-2 md:mt-7 mt-5 md:mx-7 mx-3">
                  <input
                    type="text"
                    name="account-number"
                    id="account-number"
                    className="block w-full rounded-md border-0 md:py-4 py-2.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
                    placeholder="Search product"
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-3 pr-5">
                    <MagnifyingGlassIcon
                      className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="md:mx-0 mx-3.5">
                  <NavTabComponent 
                    key={Math.random()}
                    nav_type="secondary"
                    active_tab={activeSelectVehicle}
                    nav_items={selectVehicleItems}
                    setActiveNavHandler={setActiveSelectVehicle}
                  />  
                </div>
                {isDeselectVehicleError && (
                  <div className="md:px-6 px-5 md:mt-5">
                    <span className="text-sm text-red-600">
                      You can't deselect vehicle that already been selected.
                    </span>
                  </div>
                )}
                {isDisplaySelectionError && (
                  <div className="md:px-6 px-5 md:mt-5">
                    <span className="text-sm text-red-600">
                      You can only select another vehicle up to 1 vehicle
                    </span>
                  </div>
                )}
                <div className="flex flex-wrap justify-center">
                  {/* single card (start) */}
                  <div className="flex flex-col w-full md:pb-10 pb-8 md:w-full md:px-0">
                    {activeSelectVehicle.includes("Item") && (
                      <>
                        {vehicleList && vehicleList.length !== 0 && (
                          <>
                            <div className="md:px-6 px-5 justify-center h-60 overflow-y-scroll">
                              <div className="table w-full pt-6">
                                <div className="table-row-group">
                                  {vehicleList.map((vehicle: VehicleListType) => {
                                    return (
                                      <div
                                        key={generateKey(
                                          vehicle.vehicle_id.toString() +
                                            Math.random()
                                        )}
                                      >
                                        <div className="table-row">
                                          <div className="table-cell text-center md:w-1/12">
                                            <input 
                                              id={`cb` + vehicle.vehicle_id}
                                              name={`cb` + vehicle.vehicle_id}
                                              type="checkbox"
                                              checked={selectedVehicleID.includes(
                                                vehicle.vehicle_id
                                              )}
                                              className="h-5 w-5 rounded border-gray-300 text-dealogikal-100 focus:ring-gray-50"
                                              value={vehicle.vehicle_id}
                                              onChange={(event) =>
                                                selectionHandler(
                                                  parseInt(event.target.value)
                                                )
                                              }
                                            />
                                            </div>
                                            <div className="table-cell md:w-10/12">
                                                <span className="font-normal  md:text-base text-xs ml-4">
                                                  <span className="font-extrabold text">
                                                    {`${vehicle.plate_number}`}
                                                  </span>
                                                  <span className="text-gray-500">
                                                    {` [ ${vehicle.vehicle_type_name} ]`}
                                                 </span>  
                                                </span>
                                            </div>
                                        </div>
                                        <br />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="text-center md:mt-4 md:mx-0 mx-5">
                            <ButtonComponent 
                              text="Confirm"
                              utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 ${
                                selectedVehicle.length === 2
                                ? "disabled:opacity-50 cursor-arrow"
                                : "hover:bg-dealogikal-200 cursor-pointer"
                              } duration-500 rounded-full font-normal md:w-11/12 w-full`}
                              onClick={confirmSelectionHandler}
                              disabled={
                                selectedVehicle.length === 2 ? true : false
                              }
                            /> 
                          </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            }
           />  
          </>
        }
      />
    </>
  )
}

export default SetDetailsComp;

