import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import React, { useEffect, useState } from "react";
import TableComponent from "../../templates/TableComponent/TableComponent";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { getAccreditationAPI } from "../../../redux/functions/API";
import { getTokenFromLocalStorage } from "../../../redux/functions/function";
import { TokenModel } from "../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import AccreditationDetailsType from "../../../model/types/AccreditationDetailsType";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const LogisticAccreditationComp: React.FC = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState<string>("All");
  const setActiveNavTab = (active_item: string) => {
    setActiveNav(active_item);
    setAccreditDetailsFetching(true);
  };

  const [accreditDetails, setAccreditDetails] = useState<
    AccreditationDetailsType[]
  >([]);

  const [accreditDetailsFetching, setAccreditDetailsFetching] =
    useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1); // Keep track of the current page
  const [rowsPerPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const totalPages = Math.ceil(accreditDetails.length / rowsPerPage);
  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = accreditDetails.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber: number) => {
    // Ensure page number is within bounds (1 <= pageNumber <= totalPages)
    if (pageNumber < 1) {
      setCurrentPage(1); // Prevent navigating to a page below 1
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages); // Prevent navigating to a page above the totalPages
    } else {
      setCurrentPage(pageNumber);
    }
  };
  

  const nav_items = [
    {
      text: "All",
    },
    {
      text: "Approved",
    },
    {
      text: "Pending",
    },
  ];

  const capitalizeFirstLetter = (string:any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  const tableHeader = [
    {
      name: "accreditor_name",
      value: "Supplier Name",
      icon: ClipboardIcon,
    },
    {
      name: "plate_number",
      value: "Plate Number",
      icon: ClipboardIcon,
    },
    {
      name: "vehicle_status",
      value: "Status", // You can keep this for consistency; it will be used in the table header
      icon: ClipboardIcon,
      customRender: (item:any) => (
        <span className={item.is_active === 1 && item.vehicle_status === "approved"? "text-green-500" : "text-orange-500"}>
          {item.is_active === 1 && item.vehicle_status === "approved" ? "Active" : capitalizeFirstLetter(item.vehicle_status)} {/* Use custom rendering */}
        </span>
      ),
    },
  ];
  
  <TableComponent
    tableHeader={tableHeader}
    tableData={accreditDetails}
    currentPage={1}
    rowsPerPage={10}
    idStringProperty="id"
    emptyState={{
      primaryMessage: "No Data",
      secondaryMessage: "Get Started",
      buttonText: "Add Data",
      buttonOnClick() {},
    }}
  />
  
  
  useEffect(() => {
    const fetchData = async () => {
      const user_id = userData._id.toLocaleString();
      let status = "";
      switch (activeNav) {
        case "All":
          status = "all";
          break;
        case "Approved":
          status = "approved";
          break;
        case "Pending":
          status = "pending";
          break;
      }

      navigate(`/l/accreditation/${status}`);

      if (activeNav === "All") {
        getAccreditationAPI(_token, user_id).then((response: any) => {
          console.log(response.data.items.length)
          setAccreditDetails(response.data.items);
          setTotalRecords(response.data.items.length);
          setAccreditDetailsFetching(false);
        });
      } else if (activeNav === "Approved") {
        getAccreditationAPI(_token, user_id, status).then((response: any) => {
          setAccreditDetails(response.data.items);
          setAccreditDetailsFetching(false);
        });
      } else if (activeNav === "Pending") {
        getAccreditationAPI(_token, user_id, status).then((response: any) => {
          setAccreditDetails(response.data.items);
          setAccreditDetailsFetching(false);
        });
      }
    };
    fetchData();
  }, [activeNav, _token, userData._id, navigate, setAccreditDetailsFetching]);
  
  

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/*-----------------------------------------------------------------------------------------------------------*/}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle="Accredited Trucks"
            />
            {/*-----------------------------------------------------------------------------------------------------------*/}
            <NavTabComponent
              key={Math.random()}
              nav_type="primary"
              active_tab={activeNav}
              nav_items={nav_items}
              setActiveNavHandler={setActiveNavTab}
              left_content={<></>}
            />
            <div className="flex flex-wrap justify-center">
              <div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
                {activeNav === "All" && (
                  <>
                    {accreditDetailsFetching ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <>
                        <TableComponent
                          tableHeader={tableHeader}
                          tableData={currentItems}
                          currentPage={currentPage}
                         rowsPerPage={rowsPerPage}
                          totalRecords={totalRecords}
                          idStringProperty="id"
                          emptyState={{
                            primaryMessage: "No Data",
                            secondaryMessage: "Get Started",
                            buttonText: "Add Data",
                            buttonOnClick() {},
                          }}
                            handlePageChange={paginate}
                        />
                      </>
                    )}
                  </>
                )}
                {activeNav === "Approved" && (
                  <>
                    {accreditDetailsFetching ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <>
                        <TableComponent
                          tableHeader={tableHeader}
                          tableData={currentItems}
                          currentPage={currentPage}
                         rowsPerPage={rowsPerPage}
                          totalRecords={totalRecords}
                          idStringProperty="id"
                          emptyState={{
                            primaryMessage: "No Data",
                            secondaryMessage: "Get Started",
                            buttonText: "Add Data",
                            buttonOnClick() {
                              navigate("/");
                            },
                          }}
                          handlePageChange={paginate}
                        />
                      </>
                    )}
                  </>
                )}
                {activeNav === "Pending" && (
                 <>
                 {accreditDetailsFetching ? (
                   <div className="-my-2">
                     <Skeleton height={40} count={1} />
                     <Skeleton height={40} count={5} />
                   </div>
                 ) : (
                   <>
                     <TableComponent
                       tableHeader={tableHeader}
                       tableData={currentItems}
                       currentPage={currentPage}
                       rowsPerPage={rowsPerPage}
                       totalRecords={totalRecords}
                       idStringProperty="id"
                       emptyState={{
                         primaryMessage: "No Data",
                         secondaryMessage: "Get Started",
                         buttonText: "Add Data",
                         buttonOnClick() {},
                       }}
                       handlePageChange={paginate}
                     />
                   </>
                 )}
               </>
                )}
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default LogisticAccreditationComp;
