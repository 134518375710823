import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import { useEffect, useState } from "react";
import TableComponent from "../../../templates/TableComponent/TableComponent";
import { CalendarDaysIcon, ClipboardIcon, DocumentCheckIcon, MapPinIcon, UserCircleIcon, TruckIcon, TagIcon } from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";
import { getQuotationAPI } from "../../../../redux/functions/API";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import QuotationListType from "../../../../model/types/QuotationListType";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const QuotationListComp = () => {
  const _token = getTokenFromLocalStorage() ?? '';
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState<any>('');
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [quotationList, setQuotationList] = useState<QuotationListType[]>([]);

  const setActiveNavTab = (active_item: any) => {
    // const activeNavItem = nav_items.find(item => item.text === active_item);
    setActiveNav(active_item);
    setCurrentPage(1);
  };
  const [currentPage, setCurrentPage] = useState<number>(1); // Keep track of the current page
  const [rowsPerPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const totalPages = Math.ceil(quotationList.length / rowsPerPage);
  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = quotationList.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber: number) => {
    // Ensure page number is within bounds (1 <= pageNumber <= totalPages)
    if (pageNumber < 1) {
      setCurrentPage(1); // Prevent navigating to a page below 1
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages); // Prevent navigating to a page above the totalPages
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const nav_items = [
    { text: 'All', id: 0, url: 'all' },
    { text: 'In Progress', id: 1, url: 'in-progress' },
    { text: 'Waiting', id: 2, url: 'waiting' },
    { text: 'Expired', id: 3, url: 'expired' },
    { text: 'Completed', id: 4, url: 'completed' },
  ];

  const tableHeader = [
    { name: 'reference_number', value: 'Reference Number', icon: TagIcon },
    { name: 'company_name', value: 'Buyer Name', icon: UserCircleIcon },
    { name: 'delivery_location', value: 'Delivery Location', icon: MapPinIcon },
    { name: 'delivery_date', value: 'Delivery Date', icon: CalendarDaysIcon },
    { name: 'shipping_method', value: 'Shipping Method', icon: TruckIcon },
    { name: 'supplier_count', value: 'Supplier', icon: ClipboardIcon },
    { name: 'logistic_count', value: 'Logistic', icon: ClipboardIcon },
    { name: 'status', value: 'Status', icon: DocumentCheckIcon },
  ];

  const status = nav_items.find(item => item.text === activeNav) || { id: 0, url: 'all' };

  useEffect(() => {
    const fetchData = async () => {
      setIsListLoading(true);
      try {
        const response:any = await getQuotationAPI(_token, status.id);
        setQuotationList(response.data.items);
        setTotalRecords(response.data.items.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsListLoading(false);
      }
      // Fix: bug on 86cxbmwa4
      //navigate(`/a/quotation/${status.url}`);
    };
    fetchData();
  }, [_token, activeNav, navigate, status.url, status.id]);

  // created a function for handling the table row click
  const handleRowClick = (reverse_auction_id: number | string) => {
    navigate(`/b/reverse_auction/${reverse_auction_id}`);
  };

  // const createTemplateClickHandler = () => {
  //   navigate("/b/select_commodity");
  // };

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            <BreadcrumbContainerComponent key={Math.random()} 
              subtitle="Active Quotation" />
            <NavTabComponent
              key={Math.random()}
              nav_type="primary"
              active_tab={activeNav}
              nav_items={nav_items}
              setActiveNavHandler={setActiveNavTab}
            />
            <div className="flex flex-wrap justify-center">
              <div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
                {isListLoading ? (
                  <div className="-my-2">
                    <Skeleton height={40} count={1} />
                    <Skeleton height={40} count={5} />
                  </div>
                ) : (
                    <TableComponent
                      tableHeader={tableHeader}
                  tableData={currentItems}
                  currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={totalRecords}
                    idStringProperty="reverse_auction_id"
                    handleRowClick={handleRowClick}
                    emptyState={{
                      primaryMessage: 'No Data',
                      secondaryMessage: 'Get Started',
                      buttonText: 'Add Data',
                      buttonOnClick() {
                        // createTemplateClickHandler();
                      },
                    }}
                    handlePageChange={paginate}
                  />
                )}
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default QuotationListComp
;
