import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import React, { useEffect, useState } from "react";
import TableComponent from "../../templates/TableComponent/TableComponent";
import {
	CalendarDaysIcon,
	ClipboardIcon,
	DocumentCheckIcon,
	MapPinIcon,
	UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { getPreOrderAPI } from "../../../redux/functions/API";
import { getTokenFromLocalStorage } from "../../../redux/functions/function";
import { TokenModel } from "../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import PreOrderDetailsType from "../../../model/types/PreorderDetailsType";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LogisticPreOrderComp: React.FC = () => {
	const _token = getTokenFromLocalStorage() ?? "";
	const userData: TokenModel = jwt_decode(_token);
	const navigate = useNavigate();
	const [activeNav, setActiveNav] = useState<string>("All");
	const [isListLoading, setIsListLoading] = useState<boolean>(true);
	const [preorderDetails, setPreorderDetails] = useState<PreOrderDetailsType[]>(
		[]
	);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const rowsPerPage: number = 10;
	const setActiveNavTab = (active_item: string) => {
		setActiveNav(active_item);
	};

	const nav_items = [
		{ text: "All" },
		{ text: "In Progress" },
		{ text: "Expired" },
	];

	const tableHeader = [
		{ name: "buyer_name", value: "Buyer Name", icon: UserCircleIcon },
		{ name: "commodities", value: "Commodities", icon: ClipboardIcon },
		{ name: "location", value: "Delivery Location", icon: MapPinIcon },
		{
			name: "date_submitted",
			value: "Quotation Date",
			icon: DocumentCheckIcon,
			customRender: (row: any) => {
				const date = new Date(row.date_submitted);
				return `${date.getFullYear()}-${date
					.getDate()
					.toString()
					.padStart(2, "0")}-${date.getMonth() + 1}`;
			}, // Inline formatting function MM-DD-YYYY
		},
		{
			name: "delivery_date",
			value: "Delivery Date",
			icon: CalendarDaysIcon,
			customRender:(row: any)=>{
				const date = new Date(row.delivery_date);
				return `${date.getFullYear()}-${date
					.getDate()
					.toString()
					.padStart(2, "0")}-${date.getMonth() + 1}`;
			}
		},
	];

	// this is the syntax to fetch the all data
	useEffect(() => {
		const fetchData = async () => {
			setIsListLoading(true);
			const user_id = userData._id.toString();
			let status_id = 0;
			switch (activeNav) {
				case "In Progress":
					status_id = 1;
					break;
				case "Expired":
					status_id = 3;
					break;
				case "All":
				default:
					status_id = 0;
					break;
			}

			try {
				const response: any = await getPreOrderAPI(
					_token,
					user_id,
					status_id,
					currentPage,
					rowsPerPage
				);
				if (response.statusCode === 200) {
					setIsListLoading(false);
					setPreorderDetails(response.data.items.data);
					setTotalRecords(response.data.items.totalRecords);
				} else {
					setPreorderDetails([]); // Clear the list if the response is unexpected
				}
			} catch (error) {
				console.error("Error fetching preorder details:", error);
			} finally {
				setIsListLoading(false);
			}

			navigate(
				`/l/pre_order/${
					status_id === 0 ? "all" : status_id === 1 ? "in-progress" : "expired"
				}`
			);
		};
		fetchData();
	}, [activeNav, _token, navigate, userData._id, currentPage]);

	const handleRowClick = (reverse_auction_id: number | string) => {
		navigate(`/l/reverse_auction/${reverse_auction_id}`);
	};

	// Handle page change from TableComponent
	const handlePageChange = (page: number) => {
		setCurrentPage(page); // Update current page state
	};

	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						<BreadcrumbContainerComponent
							key={Math.random()}
							subtitle="Pre Orders"
						/>
						<NavTabComponent
							key={Math.random()}
							nav_type="primary"
							active_tab={activeNav}
							nav_items={nav_items}
							setActiveNavHandler={setActiveNavTab}
							left_content={<></>}
						/>
						<div className="flex flex-wrap justify-center">
							<div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
								{isListLoading ? (
									<div className="-my-2">
										<Skeleton height={40} count={1} />
										<Skeleton height={40} count={5} />
									</div>
								) : (
									<TableComponent
										tableHeader={tableHeader}
										tableData={preorderDetails}
										currentPage={currentPage}
										rowsPerPage={rowsPerPage}
										totalRecords={totalRecords}
										idStringProperty="reverse_auction_id"
										handleRowClick={handleRowClick}
										emptyState={{
											primaryMessage: "No Data",
											secondaryMessage: "Get Started",
											buttonText: "Add Data",
											buttonOnClick: () => navigate("/"),
										}}
										handlePageChange={handlePageChange}
									/>
								)}
							</div>
						</div>
					</>
				}
			/>
		</>
	);
};

export default LogisticPreOrderComp;
