import LogisticRateComp from "../../../../components/logistic/rates/logistic_rate/LogisticRateComp";
import { ToasterProvider } from "../../../../context/ToasterContext";

const LogisticRate = () => {
  return (
    <ToasterProvider>
      <LogisticRateComp />
    </ToasterProvider>
  );
};

export default LogisticRate;
